import * as React from 'react';
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RowTable from './TableRow';
import { createData, Excel_data, get_report_data, rows } from "./helpers/helpers"
import TotalNetTable from './TotalNetTable';
import TotalDetailsTable from './TotalDetailsTable';

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import deLocale from "date-fns/locale/ar-DZ";
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import PrintModal from './PrintModal';
import { Download_Excel_Report } from './utils';


export default function CollapsibleTable() {
  const [reportData, setreportData] = useState([])
const [Today, setToday] = useState(new Date())
  useEffect(() => {


    get_report_data(Today.getFullYear() + "-" + (Today.getMonth() + 1) + "-" + Today.getDate(), localStorage.getItem('company_supabase_id')).then(res => {
      console.log("report data")
      setreportData(res)
    })

   

  }, [Today])

  const download_Data=(data,Date)=>{
    Download_Excel_Report(data,Date)
  }



  return (
    <>
      <Box sx={{ width: '100%', height: '50px', backgroundColor: "", padding: '10px',display:"flex" }}>
        <Box  sx={{width:'300px',marginBottom:"10px"}}>
        التاريخ  
        <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
<DatePicker value={Today} format="dd-MMMM-yyyy" onChange={setToday} />
  
  {/* من{fromdate.getFullYear() + "-" + (fromdate.getMonth() + 1) + "-" + fromdate.getDate()} */}
</MuiPickersUtilsProvider>
        </Box>
        <Box sx={{width:'500px',display:"flex",justifyContent:"space-between",alignItems:'center'}}>
        <PrintModal data={reportData}/>
          <Button variant="contained" dir='rtl'   onClick={()=>download_Data(reportData,Today)}  >Excel <Download/> </Button>
        </Box>
                
      </Box>

      <TotalNetTable data={reportData}/>
      <TotalDetailsTable data={reportData}/>

{JSON.stringify(reportData)}


    </>
  );
}
