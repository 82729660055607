import supabase from "src/supabase.config";





export const Open_caisse=async(Open_Amount,caisse)=>{
    const { data, error } = await supabase
    .from('Total_Day_caisse')
    .update({ Open_Amount: Open_Amount })
    .eq('caisse', caisse)
    .select()
    if(data)return data
}


export const income_open = async (caisse, B2000, B1000, B500, B200, C200, C100, C50, C20, C10, C5) => {
    const { data, error } = await supabase
        .from('income_caisse')
        .insert([
            {
                caisse: caisse, B2000: B2000, B1000: B1000, B500: B500, B200: B200,
                sold:B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5,
                PaperSold:B2000*2000+B1000*1000+B500*500+B200*200,
                CoinSold:C200*200+C100*100+C50*50+C20*20+C10*10+C5*5,
                C200: C200, C100: C100, C50: C50, C20: C20, C10: C10, C5: C5, code: "افتتاح"
            },
        ])
        .select()
    if (data) return data
    if (error) return error
}



export const create_job_is_new_open=async(caisse_id)=>{
    
const { data, error } = await supabase
.from('Job_caisse')
.insert([
  { open: true, caisse: caisse_id },
])

}



export const Is_open=async(caisse_id)=>{

    let { data: Job_caisse, error } = await supabase
    .from('Job_caisse')
    .select('*')
    .eq('caisse',caisse_id)
    .eq('open',true)
    if(Job_caisse) return Job_caisse
  
}