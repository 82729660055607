import { faker } from '@faker-js/faker';
// @mui
import { useState, useEffect } from "react"
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import axios from "axios"
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import AppWidgetSpending from './spend/AppWidgetSpending';
import { baseurl } from 'src/utils/constant';
import currencyFormatter from "currency-formatter"

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();

  const [company, setcompany] = useState('');
  const [reportdata, setreportdata] = useState([])
  const [monyyear, setmonyyear] = useState(2022)
  const handleChangecompany = (event) => {
    setcompany(event.target.value);
    console.log(event.target.value)
    localStorage.setItem('company_id', event.target.value)
    let companyname = ""
    if (event.target.value == 1) {
      companyname = "IFRI"
    }
    if (event.target.value == 2) {
      companyname = "MMD"
    }
    if (event.target.value == 3) {
      companyname = "MMG"
    }
    localStorage.setItem('depot', companyname)
  };

  const handlemonyyear = (event) => {
    setmonyyear(event.target.value);
  };

  const company_id = JSON.parse(localStorage.getItem("company_id"))
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

  }, [monyyear, localStorage.getItem('company_id')]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">

        <Box sx={{ width: '100%', height: '100px', backgroundColor: ' ', display: 'flex', justifyContent: "space-between" }}>

          {/* <FormControl sx={{ width: '150px' }}>
            <InputLabel id="demo-simple-select-label">الفرع</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={company}
              label="Age"
              onChange={handleChangecompany}
            >
              <MenuItem value={1}>IFRI</MenuItem>
              <MenuItem value={2}>MMD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl sx={{ width: '150px' }}>
            <InputLabel id="demo-simple-select-label">   السنة المالية</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={monyyear}
              label="Age"
              onChange={handlemonyyear}
            >
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={22021}>2021</MenuItem>
              <MenuItem value={2020}>2020</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h4" sx={{ mb: 5 }}>
            احصائيات الصندوق
          </Typography>
        </Box>
       
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="الزبائن" total={reportdata.numberclients} color="info" icon={'ant-design:team-outlined'} />
          </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="الربح" total={currencyFormatter.format(reportdata.totalincome, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} color="success" icon={'ant-design:dollar-circle-filled'} />

          </Grid>

         

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="المداخيل" total={currencyFormatter.format(reportdata.totalincome, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} color="success" icon={'ant-design:dollar-circle-filled'} />

          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title=" المصاريف" total={currencyFormatter.format(reportdata.totalspending, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} color="error" icon={'ant-design:dollar-circle-filled'} />

          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits
              title="الربح والمصروفات"
              subheader="(+43%) من العام الماضي"
              chartLabels={[
                '01/01/2022',
                '02/01/2022',
                '03/01/2022',
                '04/01/2022',
                '05/01/2022',
                '06/01/2022',
                '07/01/2022',
                '08/01/2022',
                '09/01/2022',
                '10/01/2022',
                '11/02/2022',
              ]}
              chartData={[
                {
                  name: 'الربح',
                  type: 'line',
                  fill: 'solid',
                  data: [130.00, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
                {
                  name: 'المداخيل',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'المصاريف',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },

              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="المنتجات"
              chartData={[
                { label: 'افري', value: 4344 },
                { label: 'مندو', value: 5435 },
                { label: 'عبيدي', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
