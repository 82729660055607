import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Approval, Check, NoTransferSharp, PrintSharp } from '@mui/icons-material';
import { CloseIcon } from '@chakra-ui/icons';
import CountingForm from './CountingForm';
import Scrollbar from 'src/components/Scrollbar';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 670,
    bgcolor: 'white',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function PrintAproveProcedding({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);




    const Approve = async () => {

    }
    return (
        <div>
            <Button onClick={handleOpen}>
                <PrintSharp />
                طباعة محضر المعاينة على حساب مال الخزينة
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Scrollbar>
                        {/* <div style={{ height: '600px', backgroundColor: "white" }}> */}

                        <CountingForm data={data} />
                        {/* </div> */}


                    </Scrollbar>




                    <Box sx={{ width: "80%", height: "30px", display: "flex", justifyContent: "space-between", backgroundColor: "" }}>
                        <Button onClick={Approve} variant="contained">
                            طباعة <PrintSharp />
                        </Button>
                        <Button onClick={handleClose} variant="contained">
                            الغاء
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
