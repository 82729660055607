import supabase from "src/supabase.config";
var arraySort = require('array-sort');
export const get_all_operation = async (caisse,startDate,endDate) => {
    let All_Data = []
    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq("caisse", caisse)
        .gte("Date",startDate)
        .lte("Date",endDate)
    if (income_caisse) {
        
        let { data: spend_caisse, error } = await supabase
            .from('spend_caisse')
            .select('*')
            .eq("caisse", caisse)
            .gte("Date",startDate)
            .lte("Date",endDate)
        if (spend_caisse) {
            return All_Data = arraySort([...income_caisse, ...spend_caisse],"Time")
        }
    }
   

}