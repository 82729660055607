import { useState,useEffect } from 'react';
import { Flex, ListIcon, StatDownArrow } from '@chakra-ui/react';
// material
import { Box, Button, Chip, Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
// mock
import PRODUCTS from '../../_mock/products';

import ControlTab from "./controltab"
import supabase from 'src/supabase.config';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

// Add on Library
import currencyFormatter from "currency-formatter"

export default function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);
  const TotalIncome = useSelector((state) => state.settings.TotalDetailIncome)
  const TotalSPend = useSelector((state) => state.settings.TotalDetailSpend)
  const WinningMoeny = TotalIncome - TotalSPend
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const [company, setcompany] = useState('');

  
  const [openstate, setopenstate] = useState(false)
  const getstate = async () => {
    let { data: caisse, error } = await supabase
      .from('caisse_sondok')
      .select('open')
      .eq('id',localStorage.getItem('company_supabase_id'))
    if (caisse) {
      console.log(caisse[0].open)
      setopenstate(caisse[0].open)
      console.log("i am Open Baby")
    }

  }
  useEffect(() => {
    getstate()

  }, [])
  
 
  return (
    <Page title="Dashboard: Products">
      <Container sx={{ direction: "rtl" }}>

        <Typography variant="h5" sx={{ mb: 5, ml: 80 }}>
          {/* كشف الصندوق      <Chip label={JSON.parse(localStorage.getItem('log')).company} /> */}
        </Typography>


        <Typography variant="h5" sx={{ ml: 90 }}>
          {openstate ? "الصندوق مفتوح" : "الصندوق مغلق"}
        </Typography>

        <Box sx={{ width: '100%', display: "flex", justifyContent: "flex-end", alignItems: "center", height: 'auto' }}>
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" gap={3} justifyContent="flex-end" sx={{ mb: 5 }}>

            {/* <Chip sx={{ color: "yellowgreen" }} label={"  اجمالي المداخيل" + currencyFormatter.format(TotalIncome, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} />
            <Chip sx={{ color: "tomato" }} label={"  اجمالي المصاريف" + currencyFormatter.format(TotalSPend, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} />
            <Chip sx={{ color: "black" }} label={"صافي الربح" + currencyFormatter.format(WinningMoeny, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })} /> */}


          </Stack>


        </Box>
        <ControlTab />

        <ProductCartWidget />
      </Container>
    </Page>
  );
}
