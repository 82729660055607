import * as React from 'react';
import {useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
import { edit_customer, edit_taxpayer, update_customer } from './utils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 300,
    bgcolor: 'white',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};

export default function EditTaxPayer({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
const [label, setlabel] = useState(data?.name)
const [last_name, setlast_name] = useState(data?.last_name)
const [value, setvalue] = useState(data?.value)



const updateTaxpayer=()=>{
 
edit_taxpayer(data?.id,last_name,label).then(res=>{
    handleClose()
})

}
    return (
        <div>
            <Button onClick={handleOpen}><Edit sx={{ color: "yellowgreen" }} /> </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        تعديل المكلف
                    </Typography>
                    <SimpleGrid columns={2} spacing={10}>
                        <TextField id="outlined-basic" label="الاسم" variant="outlined" onChange={(e)=>setlabel(e.target.value)} value={label} />
                        <TextField id="outlined-basic" label="اللقب" variant="outlined" onChange={(e)=>setlast_name(e.target.value)} value={last_name} />
                        <TextField id="outlined-basic" label="الشركة" variant="outlined" />
                        <TextField id="outlined-basic" label="رمز الزبون" variant="outlined" onChange={(e)=>setvalue(e.target.value)} value={value} />

                    </SimpleGrid>

                    <Box sx={{ width: "100%", marginTop: 8, height: "30px", display: "flex", justifyContent: "space-between" }}>
                        <Button variant="contained" onClick={updateTaxpayer}>
                            تعديل
                        </Button>
                        <Button variant="contained">
                            الغاء
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
