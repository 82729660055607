import { format, formatDistanceToNow } from 'date-fns';
import { ar, arDZ } from 'date-fns/locale';
import arSA from 'date-fns/locale/ar-SA';
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date),'    dd  MMMM yyyy  ',{locale:arDZ});
}

export function fDateData(date) {
  return format(new Date(date),'    MM  dd yyyy  ');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}


var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

export const todaydate=mm + '-' + dd + '-' + yyyy
