import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import axios from "axios"
import { Autocomplete, Badge, Chip, Grid, Input, InputAdornment, OutlinedInput, Paper, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updatespendingData } from '../redux/slices';
import { AccountBalance, AccountCircle, FactCheck, Verified, VerifiedRounded } from '@mui/icons-material';
import B2000 from "./images/2000DAB.jpg"
import B1000 from "./images/1000DAB.jpg"
import B500 from "./images/500DAB.jpg"
import B200 from "./images/200DAB.jpg"
import C5 from "./images/5DAC.jpg"
import C10 from "./images/10DAC.jpg"
import C20 from "./images/20DAC.jpg"
import C50 from "./images/50DAC.jpg"
import C100 from "./images/100DAC.jpg"
import C200 from "./images/C200.jpg"
import { faker } from '@faker-js/faker';
import randomword from "random-words"
import { baseurl } from 'src/utils/constant';
import currencyFormatter from "currency-formatter";
import LoadingButton from '@mui/lab/LoadingButton';
import { doorsmmd,doorsall } from 'src/utils/SpendHelper';
//Supabase
import supabase from 'src/supabase.config';

import { SimpleGrid } from '@chakra-ui/react'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib


import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


// Material UI
import Alert from '@mui/material/Alert';
import { get_concerned, get_taxpayer } from 'src/utils/Stuf';
import { ar } from 'date-fns/locale';
import { get_open_job_id } from '../income/utils';

let doors=[]
if(JSON.parse(localStorage.getItem('company_supabase_id')==9)){
  doors=doorsmmd
}
else{
  doors=doorsall
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicModal() {
  const [uploaded, setuploaded] = useState(false)
  const dispatch = useDispatch()
  var TotalIncome = 0;
  var TotalSpending = 0;
  const [IncomeData, setIncomeData] = useState([]);
  const [SpendingData, setSpendingData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [sold, setsold] = useState(0);
  const [entrance, setentrance] = useState([]);
  const [door, setdoor] = useState();
  const [section, setsection] = useState([]);
  const [concerned, setconcerned] = useState("");
  const [taxpayer, settaxpayer] = useState("");
  const [description, setdescription] = useState("");
  const [desable, setdesable] = useState(false);
  const [doorname, setdoorname] = useState("");
  const [sectionname, setsectioname] = useState("");
  const [entrancename, setentrancename] = useState("");
  const [papersold, setpapersold] = useState(0)
  const [coinsold, setcoinsold] = useState(0)
  const [factureurl, setfactureurl] = useState("")
  const [paperone, setpaperone] = useState(0);
  const [papertwo, setpapertwo] = useState(0);
  const [papertree, setpapertree] = useState(0);
  const [paperfour, setpaperfour] = useState(0);
  const [coinone, setcoinone] = useState(0);
  const [cointwo, setcointwo] = useState(0);
  const [cointree, setcointree] = useState(0);
  const [coinfour, setcoinfour] = useState(0);
  const [coinfive, setcoinfive] = useState(0);
  const [coinsix, setcoinsix] = useState(0);
  const [note, setnote] = useState("")

  const handleUpload = async (e) => {
    let file;

    if (e.target.files) {
      file = e.target.files[0];
    }
    console.log(file.name)
    let Name_file=randomword() + file.name
    const { data, error } = await supabase.storage
      .from("facturespend")
      .upload("public/" + Name_file, file);
 
    if (data) {
      console.log(data["Key"])
      setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/facturespend/public/" + Name_file)
      //setfactureurl("https://cghyrhumhokmgnrpkelf.supabase.co/storage/v1/object/public/" + data["Key"])
      setuploaded(true)
    } else if (error) {
      console.log(error);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [age, setAge] = React.useState('');
  const [not_sold, setnot_sold] = useState(false)


  var netSold = paperone * 200 +
    papertwo * 500 +
    papertree * 1000 +
    paperfour * 2000 +
    coinone * 5 +
    cointwo * 10 +
    cointree * 20 +
    coinfour * 50 +
    coinfive * 100 +
    coinsix * 200;



  var timedata = ""
  const [last_time_data, setlast_time_data] = useState("")
  const [Concered_Data, setConcered_Data] = useState([])
  const [Taxpayers_Data, setTaxpayers_Data] = useState([])
  const [NewSpendDate, setNewSpendDate] = useState(new Date())



  const company_supabase_id = JSON.parse(localStorage.getItem('company_supabase_id'))
  const get_Time_Data = async () => {
    let { data: caisse, error } = await supabase
      .from('caisse')
      .select('Timedata').eq('id', JSON.parse(localStorage.getItem("company_id")))
    if (caisse) {
      timedata = caisse[0].Timedata
      setlast_time_data(timedata)
    }
    if (error) {
      console.log(error)
    }
 
  }
  const [sending, setsending] = useState(false)
  const sendnewpsending = async () => {
    setsending(true)
    get_open_job_id(localStorage.getItem('company_supabase_id')).then(async res=>{


  
    const { data:newspend, errornewspend } = await supabase
    .from('spend_caisse')
    .insert([
      { sold: netSold,Note:note,Facture:factureurl,job_id:res[0]?.id, Date:NewSpendDate,caisse: JSON.parse(localStorage.getItem('company_supabase_id')),Concerned:concerned?.label,Taxpayer:taxpayer?.label,PaperSold:papersold,CoinSold:coinsold ,B2000:paperfour,B1000:papertree,B500:papertwo,B200:paperone,C200:coinsix,C100:coinfive,C50:coinfour,C20:cointree,C10:cointwo,C5:coinone,Door:doorname,Section:sectionname,Entrance:entrancename},
    ])
    .select()
    if(newspend)
    
    {
      setsending(false)
      setOpen(false)

    }
  })

    let { data: caisse, error } = await supabase
      .from('caisse')
      .select('*').eq('id', JSON.parse(localStorage.getItem('company_id')))
    if (caisse[0].TotalIncome < caisse[0].TotalSpend + netSold) {
      

      setnot_sold(true)
    }
    else {
       
      setnot_sold(false)
    

    }
    if (error) {
      console.log(error)
    }





  }

  const handledoors = (id) => {
    // this all sections of that door
    setsection(doors.filter((item) => item.id == id)[0]["sections"]);
    setdoorname(doors.filter((item) => item.id == id)[0].name);
  };
  const handlesections = (id) => {
    setentrance(section.filter((item) => item.id == id)[0]["entrances"]);

    setsectioname(section.filter((item) => item.id == id)[0].name);
  };

  const handleentrances = (id) => {
    setentrancename(entrance.filter((item) => item.id == id)[0].name);
  };

  const clearvalue = () => {
    setpaperone(0)
    setpapertwo(0)
    setpapertree(0)
    setpaperfour(0)
    setcoinone(0)
    setcointwo(0)
    setcointree(0)
    setcoinfour(0)
    setcoinfive(0)
    setcoinsix(0)
    setentrancename('')
    setsectioname('')
    setsectioname('')
    setentrancename('')
    setsectioname('')
    setsectioname('')
    settaxpayer('')
    setconcerned('')
    setnote('')
  }


  useEffect(() => {
    get_concerned(localStorage.getItem('company_supabase_id')).then(res=>{
      setConcered_Data(res)
    })
    get_taxpayer(localStorage.getItem('company_supabase_id')).then(res=>{
      setTaxpayers_Data(res)
    })


    
    get_Time_Data()
    setpapersold(paperone * 200 +
      papertwo * 500 +
      papertree * 1000 +
      paperfour * 2000)
    setcoinsold(coinone * 5 +
      cointwo * 10 +
      cointree * 20 +
      coinfour * 50 +
      coinfive * 100+
      coinsix * 200)
  }, [last_time_data, paperone, papertwo, papertree, paperfour, coinone, cointwo, cointree, coinfour, coinfive, coinsix]);
  return (
    <div>
      <Button sx={{ direction: "ltr" }} onClick={() => { handleOpen(); clearvalue() }} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
        جديد
      </Button>
      <Modal
        sx={{ direction: "rtl" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <SimpleGrid columns={2} spacing={10}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
  مصروف جديد


</Typography>
         <MuiPickersUtilsProvider locale={ar} dir="rtl" utils={DateFnsUtils}>
         <FormControl fullWidth>
         <DatePicker value={NewSpendDate} onChange={setNewSpendDate} />
         </FormControl>


 {/* {todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate()} */}
 </MuiPickersUtilsProvider>

        <FormControl fullWidth>
         <InputLabel id="demo-simple-select-label">  القسم</InputLabel>
         <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           label="رمز الزبون"
           onChange={(e) => handledoors(e.target.value)}
         >
           {doors.map((door, key) => {
             return <MenuItem value={door.id}>{door.name}</MenuItem>;
           })}


         </Select>
       </FormControl>

       <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">  المدخل</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="رمز الزبون"
          onChange={(e) => handlesections(e.target.value)}
        >
          {section.map((item, key) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}


        </Select>
      </FormControl>


      <FormControl>
        <InputLabel id="demo-simple-select-label">  الباب</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="رمز الزبون"
          onChange={(e) => handleentrances(e.target.value)}
        >

          {entrance.map((item, key) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
      <Autocomplete
disablePortal
id="combo-box-demo"
options={Concered_Data}
sx={{ width: "100%" }}

value={concerned}
onChange={(event, newValue) => {
if (typeof newValue === 'string') {
setconcerned(  { label:newValue});
} else if (newValue && newValue.inputValue) {
// Create a new value from the user input
setconcerned({
  label: newValue.inputValue
});
} else {
setconcerned(newValue);
}
}}
renderInput={(params) => <TextField {...params} label="المعني" />}
/>
</FormControl>

<Autocomplete
disablePortal
id="combo-box-demo"
options={Taxpayers_Data}
sx={{ width: "100%" }}
clearOnBlur
value={taxpayer?.label}
onChange={(event, newValue) => {
if (typeof newValue === 'string') {
settaxpayer(  { label:newValue});
} else if (newValue && newValue.inputValue) {
// Create a new value from the user input
settaxpayer(   {  label: newValue.inputValue});
} else {
settaxpayer(newValue);
}
}}
renderInput={(params) => <TextField {...params} label="المكلف" />}
/>


<TextField
id="outlined-multiline-flexible"
label="الملاحضة"
multiline
rows={2}
value={note}
onChange={(e)=>setnote(e.target.value)}
/> 


<Button sx={{ marginTop: "10px", backgroundColor: "green",height:"40px" }} component="label" variant="contained">  ارفاق الفاتور    <FactCheck/>
    <input hidden accept="image/*" multiple type="file" onChange={(e) => {
      handleUpload(e); // 👈 this will trigger when user selects the file.
    }} />


  </Button>
  {

    uploaded ?

      <VerifiedRounded color="success" />


      :
      ""
  }

<Chip label={"المجوع الورقي" + currencyFormatter.format(papersold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} />



<Chip label={"المجوع المعدني" + currencyFormatter.format(coinsold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} />

 
<Chip  label={"المجوع الاجمالي" + currencyFormatter.format(netSold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} />
 


      
        </SimpleGrid>
        
  <Box sx={{ width: '100%', direction: "rtl" }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="عملة ورقية" {...a11yProps(0)} />
        <Tab label="عملة معدنية" {...a11yProps(1)} />

      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
      <Grid container spacing={2}>

        <Grid item xs={3}>

          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 2000دج   </InputLabel>

            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "120px", height: "35px" }} src={B2000} />
                </InputAdornment>
              }
              value={paperfour} onChange={(e) => setpaperfour(e.target.value)} />

          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 1000دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "120px", height: "35px" }} src={B1000} />
                </InputAdornment>
              }


              variant="outlined" value={papertree} onChange={(e) => setpapertree(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 500دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "120px", height: "35px" }} src={B500} />
                </InputAdornment>
              } variant="outlined" value={papertwo} onChange={(e) => setpapertwo(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "120px", height: "35px" }} src={B200} />
                </InputAdornment>
              } variant="outlined" value={paperone} onChange={(e) => setpaperone(e.target.value)} />
          </FormControl>
        </Grid>
      </Grid>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <Grid container spacing={2}>

        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "80px", height: "30px" }} src={C200} />
                </InputAdornment>
              }





              variant="outlined" value={coinsix} onChange={(e) => setcoinsix(e.target.value)} />

          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 100دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "120px", height: "30px" }} src={C100} />
                </InputAdornment>} variant="outlined" value={coinfive} onChange={(e) => setcoinfive(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 50دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "80px", height: "30px" }} src={C50} />
                </InputAdornment>} variant="outlined" value={coinfour} onChange={(e) => setcoinfour(e.target.value)} />   </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 20دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "80px", height: "30px" }} src={C20} />
                </InputAdornment>} value={cointree} onChange={(e) => setcointree(e.target.value)} />  </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 10دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "80px", height: "30px" }} src={C10} />
                </InputAdornment>} value={cointwo} onChange={(e) => setcointwo(e.target.value)} />      </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="standard" >
            <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 5دج   </InputLabel>
            <OutlinedInput id="outlined-basic" type='text'
              startAdornment={
                <InputAdornment position="start">

                  <img style={{ width: "80px", height: "30px" }} src={C5} />
                </InputAdornment>} value={coinone} onChange={(e) => setcoinone(e.target.value)} />  </FormControl>
        </Grid>

      </Grid>

    </TabPanel>

  </Box>
  <br></br>

  <Grid item xs={8}>
    <LoadingButton loading={sending} variant="contained" onClick={sendnewpsending}>حفظ</LoadingButton>
    <Button onClick={handleClose}>الغاء</Button>
  </Grid>
        </Box>
      </Modal>
    </div>
  );
}









// <Box sx={{width:"auto",display:"flex",justifyContent:"space-between"}}>

         
{/* <Typography id="modal-modal-title" variant="h6" component="h2">
  مصروف جديد


</Typography> */}
// <MuiPickersUtilsProvider locale={ar} dir="rtl" utils={DateFnsUtils}>
// <DatePicker value={NewSpendDate} onChange={setNewSpendDate} />

// {/* {todate.getFullYear() + "-" + (todate.getMonth() + 1) + "-" + todate.getDate()} */}
// </MuiPickersUtilsProvider>
// </Box>
// {not_sold ? <Alert sx={{ direction: 'rtl' }} severity="error">الرصيد في صندوقك غير كافي </Alert>
//   : ""
// }
// <br></br>
// <Grid container spacing={2}>

//   <Grid item xs={6}>
//     <Paper>
//       <FormControl fullWidth>
//         <InputLabel id="demo-simple-select-label">  القسم</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           label="رمز الزبون"
//           onChange={(e) => handledoors(e.target.value)}
//         >
//           {doors.map((door, key) => {
//             return <MenuItem value={door.id}>{door.name}</MenuItem>;
//           })}


//         </Select>
//       </FormControl>
//     </Paper>
//   </Grid>
//   <Grid item xs={6}>
//     <Paper>
      // <FormControl fullWidth>
      //   <InputLabel id="demo-simple-select-label">  المدخل</InputLabel>
      //   <Select
      //     labelId="demo-simple-select-label"
      //     id="demo-simple-select"
      //     label="رمز الزبون"
      //     onChange={(e) => handlesections(e.target.value)}
      //   >
      //     {section.map((item, key) => {
      //       return <MenuItem value={item.id}>{item.name}</MenuItem>;
      //     })}


      //   </Select>
      // </FormControl>

//     </Paper>
//   </Grid>
//   <Grid item xs={4}>
//     <Paper>
      // <FormControl fullWidth>
      //   <InputLabel id="demo-simple-select-label">  الباب</InputLabel>
      //   <Select
      //     labelId="demo-simple-select-label"
      //     id="demo-simple-select"
      //     label="رمز الزبون"
      //     onChange={(e) => handleentrances(e.target.value)}
      //   >

      //     {entrance.map((item, key) => {
      //       return <MenuItem value={item.id}>{item.name}</MenuItem>;
      //     })}
      //   </Select>
      // </FormControl>

//     </Paper>
//   </Grid>
//   <Grid item xs={4}>
//     <Paper>
//       {/* <TextField id="outlined-basic" label="المعني" variant="outlined" value={concerned} onChange={(e) => setconcerned(e.target.value)} />
//   */}
 
   
//       <Autocomplete
// disablePortal
// id="combo-box-demo"
// options={Concered_Data}
// sx={{ width: 200 }}

// value={concerned}
// onChange={(event, newValue) => {
// if (typeof newValue === 'string') {
// setconcerned(  { label:newValue});
// } else if (newValue && newValue.inputValue) {
// // Create a new value from the user input
// setconcerned({
//   label: newValue.inputValue
// });
// } else {
// setconcerned(newValue);
// }
// }}
// renderInput={(params) => <TextField {...params} label="المعني" />}
// />
   
//     </Paper>
//   </Grid>
//   <Grid item xs={4}>
//     <Paper>
//       {/* <TextField id="outlined-basic" label="المكلف" variant="outlined" value={taxpayer} onChange={(e) => settaxpayer(e.target.value)} />
//       */}
//       <Autocomplete
// disablePortal
// id="combo-box-demo"
// options={Taxpayers_Data}
// sx={{ width: 200 }}
// clearOnBlur
// value={taxpayer?.label}
// onChange={(event, newValue) => {
// if (typeof newValue === 'string') {
// settaxpayer(  { label:newValue});
// } else if (newValue && newValue.inputValue) {
// // Create a new value from the user input
// settaxpayer(   {  label: newValue.inputValue});
// } else {
// settaxpayer(newValue);
// }
// }}
// renderInput={(params) => <TextField {...params} label="المكلف" />}
// />
  
//     </Paper>
//   </Grid>
//   <Grid item xs={6}>
//   <TextField
// id="outlined-multiline-flexible"
// label="الملاحضة"
// multiline
// rows={2}
// value={note}
// onChange={(e)=>setnote(e.target.value)}
// />  </Grid>

  // <Button sx={{ marginTop: "10px", backgroundColor: "green",height:"40px" }} component="label" variant="contained">  ارفاق الفاتور    <FactCheck/>
  //   <input hidden accept="image/*" multiple type="file" onChange={(e) => {
  //     handleUpload(e); // 👈 this will trigger when user selects the file.
  //   }} />


  // </Button>
  // {

  //   uploaded ?

  //     <VerifiedRounded color="success" />


  //     :
  //     ""
  // }

//   <Grid container spacing={2} sx={{ marginTop: '10px' }}>
//     <Grid item xs={4}>
      // <Chip label={"المجوع الورقي" + currencyFormatter.format(papersold, {
      //   symbol: "دج",
      //   decimal: ",",
      //   thousand: ".",
      //   precision: 2,
      //   format: "%v %s", // %s is the symbol and %v is the value
      // })} />
//     </Grid>
//     <Grid item xs={4}>

      // <Chip label={"المجوع المعدني" + currencyFormatter.format(coinsold, {
      //   symbol: "دج",
      //   decimal: ",",
      //   thousand: ".",
      //   precision: 2,
      //   format: "%v %s", // %s is the symbol and %v is the value
      // })} />
//     </Grid>
//     <Grid item xs={4}>
      // <Chip label={"المجوع الاجمالي" + currencyFormatter.format(netSold, {
      //   symbol: "دج",
      //   decimal: ",",
      //   thousand: ".",
      //   precision: 2,
      //   format: "%v %s", // %s is the symbol and %v is the value
      // })} />
//     </Grid>
//   </Grid>

  // <Box sx={{ width: '100%', direction: "rtl" }}>
  //   <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  //     <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
  //       <Tab label="عملة ورقية" {...a11yProps(0)} />
  //       <Tab label="عملة معدنية" {...a11yProps(1)} />

  //     </Tabs>
  //   </Box>
  //   <TabPanel value={value} index={0}>
  //     <Grid container spacing={2}>

  //       <Grid item xs={3}>

  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 2000دج   </InputLabel>

  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "120px", height: "35px" }} src={B2000} />
  //               </InputAdornment>
  //             }
  //             value={paperfour} onChange={(e) => setpaperfour(e.target.value)} />

  //         </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 1000دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "120px", height: "35px" }} src={B1000} />
  //               </InputAdornment>
  //             }


  //             variant="outlined" value={papertree} onChange={(e) => setpapertree(e.target.value)} />
  //         </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 500دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "120px", height: "35px" }} src={B500} />
  //               </InputAdornment>
  //             } variant="outlined" value={papertwo} onChange={(e) => setpapertwo(e.target.value)} />
  //         </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "120px", height: "35px" }} src={B200} />
  //               </InputAdornment>
  //             } variant="outlined" value={paperone} onChange={(e) => setpaperone(e.target.value)} />
  //         </FormControl>
  //       </Grid>
  //     </Grid>
  //   </TabPanel>
  //   <TabPanel value={value} index={1}>
  //     <Grid container spacing={2}>

  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 200دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "80px", height: "30px" }} src={C200} />
  //               </InputAdornment>
  //             }





  //             variant="outlined" value={coinsix} onChange={(e) => setcoinsix(e.target.value)} />

  //         </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 100دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "120px", height: "30px" }} src={C100} />
  //               </InputAdornment>} variant="outlined" value={coinfive} onChange={(e) => setcoinfive(e.target.value)} />
  //         </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 50دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "80px", height: "30px" }} src={C50} />
  //               </InputAdornment>} variant="outlined" value={coinfour} onChange={(e) => setcoinfour(e.target.value)} />   </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 20دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "80px", height: "30px" }} src={C20} />
  //               </InputAdornment>} value={cointree} onChange={(e) => setcointree(e.target.value)} />  </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 10دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "80px", height: "30px" }} src={C10} />
  //               </InputAdornment>} value={cointwo} onChange={(e) => setcointwo(e.target.value)} />      </FormControl>
  //       </Grid>
  //       <Grid item xs={3}>
  //         <FormControl variant="standard" >
  //           <InputLabel htmlFor="outlined-adornment-password" sx={{ padding: "10px" }}> 5دج   </InputLabel>
  //           <OutlinedInput id="outlined-basic" type='text'
  //             startAdornment={
  //               <InputAdornment position="start">

  //                 <img style={{ width: "80px", height: "30px" }} src={C5} />
  //               </InputAdornment>} value={coinone} onChange={(e) => setcoinone(e.target.value)} />  </FormControl>
  //       </Grid>

  //     </Grid>

  //   </TabPanel>

  // </Box>

  // <Grid item xs={8}>
  //   <LoadingButton loading={sending} variant="contained" onClick={sendnewpsending}>حفظ</LoadingButton>
  //   <Button onClick={handleClose}>الغاء</Button>
  // </Grid>
// </Grid>