import { VisibilityTwoTone } from '@mui/icons-material'
import Visibility from '@mui/icons-material/Visibility'
import { Button, TextField, Tooltip } from '@mui/material'
import React from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { useEffect, useState } from 'react'
import { get_customer_info } from './utils';
import { SimpleGrid } from '@chakra-ui/react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};



const CustomerTableModal = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setvalue] = useState()
    const [label, setlabel] = useState("")
    const [last_name, setlast_name] = useState("")
    useEffect(() => {
        console.log(props?.value)
        get_customer_info(JSON.parse(localStorage.getItem('company_supabase_id')), props?.value).then(res => {
            console.log(res)
            setlast_name(res[0].last_name)
            setlabel(res[0].label)
            setvalue(res[0].value)
        }).catch(error=>error)
    }, [props])

    return (
        <div>

            <Button onClick={handleOpen}>
                <Tooltip title="أنقر لمعرفة تفاصيل الزبون" arrow>
                    <Visibility />
                </Tooltip>

            </Button>

            {props?.value}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        تفاصيل الزبون
                    </Typography>

                    <SimpleGrid columns={2} spacing={10}>
                        <TextField id="outlined-basic" label="رمز الزبون" variant="outlined" value={value} />
                        <TextField id="outlined-basic" label="الاسم " variant="outlined" value={label} />
                        <TextField id="outlined-basic" label="اللقب" variant="outlined" value={last_name} />

                    </SimpleGrid>
<Button variant="contained" onClick={handleClose}>
خروج
</Button>
                </Box>
            </Modal>

        </div>
    )
}

export default CustomerTableModal