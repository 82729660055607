 import { createClient } from "@supabase/supabase-js";
import { supabase_url } from "./utils/constant";

// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://cghyrhumhokmgnrpkelf.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNnaHlyaHVtaG9rbWducnBrZWxmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjU0ODI1NzIsImV4cCI6MTk4MTA1ODU3Mn0.rvx_xqShzl4lMGSm156zsUZeBrU9ozavoMgkxMhBPag"
);

export default supabase;
