import supabase from "src/supabase.config";
import { todaydate } from "./formatTime";



export const get_Balance = async (caisse) => {
    let TotalIncome = 0
    let TotalSpend = 0
    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('sold')
        .eq('caisse', caisse)
        .eq('Date',todaydate)
    if (income_caisse) {
        for (let index = 0; index < income_caisse.length; index++) {
            TotalIncome = income_caisse[index].sold + TotalIncome
        }

        let { data: spend_caisse, error } = await supabase
            .from('spend_caisse')
            .select('sold')
            .eq('caisse', caisse)
            .eq('Date',todaydate)
        if (spend_caisse) {
            for (let index = 0; index < spend_caisse.length; index++) {
                TotalSpend = spend_caisse[index].sold + TotalSpend
            }
            return (TotalIncome-TotalSpend)
        }

    }

}