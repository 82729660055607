import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Page from 'src/components/Page';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"

import supabase from 'src/supabase.config';
import EditCustomer from './EditCustomer';
import DeleteCustomer from './DeleteCustomer';
import { get_customer, operation_list_customer, operation_number_customer } from './utils';
import CustomerOperation from './CustomerOperation';
import CustomerSpending from './CustomerSpending';
import OperationTable from './OperationTable';




function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [OperationList, setOperationList] = useState([])
  const [customerOperationnumber, setcustomerOperationnumber] = useState(0)
useEffect(() => {
  operation_list_customer(JSON.parse(localStorage.getItem("company_supabase_id")),row?.value).then(res=>{
    console.log("operation List")
    console.log(res)
    setOperationList(res)
  })

 
}, [row])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box sx={{display:"flex"}}>
          <EditCustomer data={row} />
          <DeleteCustomer data={row}/>
          </Box>
        
        </TableCell>
        <TableCell align="right"><CustomerSpending customer_id={row?.value}/>  </TableCell>
        <TableCell align="right">   <CustomerOperation customer_id={row?.value}/></TableCell>
        <TableCell align="right">
        {row?.value}
        </TableCell>
        <TableCell align="right">{row.last_name}</TableCell>
        <TableCell align="right">{row.label}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, direction: "rtl" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box >
              <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: "right" }}>
                سجل
              </Typography>
            <OperationTable data={OperationList} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}





export default function CollapsibleTable() {
  const [customerList, setcustomerList] = useState([])
  const navigate = useNavigate()
  const tocustomerform = () => {
    navigate('/dashboard/newcustomer')
  }
  const [company, setcompany] = useState("MMG")
  const handleChangecompany = () => {

  }


  const get_customers = async () => {
    if (localStorage.getItem('company_supabase_id') == 1) {
      let { data: customers, error } = await supabase
        .from('customers')
        .select('id,label,value,last_name')
      if (customers) {
        setcustomerList(customers)

      }
      if (error) console.log(error)

    }
    else {
      get_customer(localStorage.getItem('company_supabase_id')).then(res => {
        setcustomerList(res)
      })
    }
  }
  useEffect(() => {
   

    get_customers()

  

  }, []);




    // Subscribe to changes in any record from the collection
    supabase
    .channel('public:CustomersGros')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'CustomersGros' }, payload => {

      get_customers()


    })
    .subscribe()
  // client.realtime.subscribe('income_caisse', function (e) {
  // Pocket base Real Time
  // });


  return (
    <Page title="Customers">

      <Box sx={{ height: '100px', width: '99%', backgroundColor: "", display: "flex", justifyContent: "space-between", padding: '30px' }}>

      

        <Button onClick={tocustomerform} variant="contained" sx={{ height: '40px' }}>
          <Add />
          اضافة زبون جديد

        </Button>
      </Box>
      <TableContainer component={Paper} sx={{width:"99%"}}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">     عمليات</TableCell>
              <TableCell align="center">قيمة مصاريف الزبون</TableCell>
              <TableCell align="center">عدد عمليات الزبون</TableCell>
              <TableCell align="center">رمز الزبون</TableCell>
              <TableCell align="right">اللقب</TableCell>
              <TableCell align="right">الاسم</TableCell>




            </TableRow>
          </TableHead>
          <TableBody>
            {customerList?.map((row) => (
              <Row key={row?.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
}
