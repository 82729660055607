



import supabase from "src/supabase.config";
import { fDateData } from "src/utils/formatTime";



export const get_procedding_income = async (date, caisse) => {

    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq('Date', fDateData(date))
        .eq('caisse', caisse)
    if (income_caisse)
        return income_caisse

}


export const get_procedding_spend = async (date, caisse) => {


    let { data: spend_caisse, error } = await supabase
        .from('spend_caisse')
        .select('*')
        .eq('Date', fDateData(date))
        .eq('caisse', caisse)
    if (spend_caisse)
        return spend_caisse
}


export const get_report_data = async (date, caisse) => {
    var reportData = []
    let TotalIncome = 0
    let TotalSpend = 0
    let Total_Income_B2000 = 0, Total_Income_B1000 = 0, Total_Income_B500 = 0, Total_Income_B200 = 0
    let Total_Income_C200 = 0, Total_Income_C100 = 0, Total_Income_C50 = 0, Total_Income_C20 = 0, Total_Income_C10 = 0, Total_Income_C5 = 0




    let Total_Spend_B2000 = 0, Total_Spend_B1000 = 0, Total_Spend_B500 = 0, Total_Spend_B200 = 0
    let Total_Spend_C200 = 0, Total_Spend_C100 = 0, Total_Spend_C50 = 0, Total_Spend_C20 = 0, Total_Spend_C10 = 0, Total_Spend_C5 = 0

    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('*')
        .eq('caisse', caisse)
        .eq("Date", fDateData(date))
    if (income_caisse) {
        let { data: spend_caisse, error } = await supabase
            .from('spend_caisse')
            .select('*')
            .eq('caisse', caisse)
            .eq("Date", fDateData(date))
        if (spend_caisse)
            console.log("data")
        for (let index = 0; index < income_caisse.length; index++) {

            TotalIncome = income_caisse[index].sold + TotalIncome

            Total_Income_B2000 = income_caisse[index].B2000 + Total_Income_B2000
            Total_Income_B1000 = income_caisse[index].B1000 + Total_Income_B1000
            Total_Income_B500 = income_caisse[index].B500 + Total_Income_B500
            Total_Income_B200 = income_caisse[index].B200 + Total_Income_B200

            Total_Income_C200 = income_caisse[index].C200 + Total_Income_C200
            Total_Income_C100 = income_caisse[index].C100 + Total_Income_C100
            Total_Income_C50 = income_caisse[index].C50 + Total_Income_C50
            Total_Income_C20 = income_caisse[index].C20 + Total_Income_C20

            Total_Income_C10 = income_caisse[index].C10 + Total_Income_C10
            Total_Income_C5 = income_caisse[index].C5 + Total_Income_C5



        }
        for (let index = 0; index < spend_caisse.length; index++) {
            TotalSpend = spend_caisse[index].sold + TotalSpend

            Total_Spend_B2000 = spend_caisse[index].B2000 + Total_Spend_B2000
            Total_Spend_B1000 = spend_caisse[index].B1000 + Total_Spend_B1000
            Total_Spend_B500 = spend_caisse[index].B500 + Total_Spend_B500
            Total_Spend_B200 = spend_caisse[index].B200 + Total_Spend_B200


            Total_Spend_C200 = spend_caisse[index].C200 + Total_Spend_C200
            Total_Spend_C100 = spend_caisse[index].C100 + Total_Spend_C100
            Total_Spend_C50 = spend_caisse[index].C50 + Total_Spend_C50
            Total_Spend_C20 = spend_caisse[index].C20 + Total_Spend_C20

            Total_Spend_C10 = spend_caisse[index].C10 + Total_Spend_C10
            Total_Spend_C5 = spend_caisse[index].C50 + Total_Spend_C5
        }




    }
    reportData.push({
        TotalIncome: TotalIncome,
        TotalSpend: TotalSpend,
        TotalNet: TotalIncome - TotalSpend,


        TotalB2000: Total_Income_B2000 - Total_Spend_B2000,
        TotalB1000: Total_Income_B1000 - Total_Spend_B1000,
        TotalB500: Total_Income_B500 - Total_Spend_B500,
        TotalB200: Total_Income_B200 - Total_Spend_B200,






        TotalC200: Total_Income_C200 - Total_Spend_C200,
        TotalC100: Total_Income_C100 - Total_Spend_C100,
        TotalC50: Total_Income_C50 - Total_Spend_C50,

        TotalC20: Total_Income_C20 - Total_Spend_C20,
        TotalC10: Total_Income_C10 - Total_Spend_C10,
        TotalC5: Total_Income_C5 - Total_Spend_C5,














    }
    )
    return reportData
}


