import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Cancel, CancelOutlined, Edit, Print } from '@mui/icons-material';

import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import currencyFormatter from "currency-formatter"

import supabase from 'src/supabase.config';
import OperationMenu from './OperationMenu';
import { cancel_transfer_counting_caisse, deactivate_counting_caisse } from './helpers';

var filterParams = {

    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('-');
        const year = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const day = Number(dateParts[0]);
        const cellDate = new Date(day, month, year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    }

};


const HistoryTable = ({ data }) => {
    const containerStyle = useMemo(() => ({ width: "900px", height: '80%', direction: "rlt" }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState();
    const [HistoryOpearation, setHistoryOpearation] = useState([])



    const TotalPrice = (props) => {
        return (
            <div>
                <Chip label={currencyFormatter.format(props.value, {
                    symbol: "دج",
                    decimal: ",",
                    thousand: ".",
                    precision: 2,
                    format: "%v %s", // %s is the symbol and %v is the value
                })} />

            </div>

        )
    }



    const ActionsButton = (props) => {

        return (
            <Box sx={{ display: "flex", justifyContent: "space-between", width: 'auto', backgroundColor: "", height: "30px" }}>
                <OperationMenu data={props?.data} />
            </Box>
        )
    }

    const [columnDefs, setColumnDefs] = useState([
        { field: 'Date', headerName: 'التاريخ', filter: false, width: 100, maxWidth: 120 },
        // { field: 'PaperSold', headerName: 'المبلغ الورقي', cellRenderer: TotalPrice,filter: false,width:100,maxWidth:120  },

        // { field: 'CoinSold', headerName: 'المبلغ المعدني', cellRenderer: TotalPrice,filter: false,width:100,maxWidth:120  },
        { field: 'NetSold', headerName: 'المبلغ الاجمالي', cellRenderer: TotalPrice, filter: false, width: 100, maxWidth: 200 },
        { field: 'user_name', headerName: '  أمين الصندوق', filter: false, width: 100, maxWidth: 350 },
        { field: 'state', headerName: '    الحالة', cellRenderer: stateCmponent, filter: false, width: 350, maxWidth: 450 },
        { field: 'type', headerName: '    نوع المحضر', cellRenderer: typeComponent, filter: false, width: 350, maxWidth: 450 },
        { field: 'Note', headerName: '    ملاحضة المحضر المحضر', cellRenderer: typeComponent, filter: false, width: 350, maxWidth: 450 },
        { field: 'validate', headerName: '    الموافقة على التحويل', cellRenderer: ValidateComponent, filter: false, width: 350, maxWidth: 450 },



        { field: 'id', headerName: 'اجرائات', width: 100, maxWidth: 200, cellRenderer: ActionsButton, filter: false },

    ]);


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            height: 200,
            minWidth: 200,
            filter: true,
            floatingFilter: true,
            enableRtl: true,
            filterParams: {



                filterOptions: ['equals']
            }
        };
    }, []);
  
    const get_history_operation = async () => {
        let { data: proceedings_couting_caisse, error } = await supabase
            .from('proceedings_couting_caisse')
            .select('*')
            .eq('caisse', localStorage.getItem("company_supabase_id"))
        if (proceedings_couting_caisse) {
            setHistoryOpearation(proceedings_couting_caisse)
        }
    }
    useEffect(() => {

        get_history_operation()
    }, [])

const refrech_data=async()=>{
    let { data: proceedings_couting_caisse, error } = await supabase
    .from('proceedings_couting_caisse')
    .select('*')
    .eq('caisse', localStorage.getItem("company_supabase_id"))
if (proceedings_couting_caisse) {
    setHistoryOpearation(proceedings_couting_caisse)
}
}
    // Subscribe to changes in any record from the collection
    supabase
        .channel('public:proceedings_couting_caisse')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'proceedings_couting_caisse' }, payload => {
           
            refrech_data()


        })
        .subscribe()

    return (

        <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                    rowData={HistoryOpearation}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    enableRtl={true}

                ></AgGridReact>
            </div>
        </div>

    )
}

export default HistoryTable






const stateCmponent = (props) => {

    const deactivate = (id) => {

        console.log(' Deactivated')
    }
    return (
        <>


            <Box sx={{ width: '350px' }}>


                {(props?.value == "created") ? <Chip label="تم الانشاء" sx={{ backgroundColor: "" }} /> : (props?.value == "approved") ?
                    <>
                        <Chip label="تم الموافقة" sx={{ backgroundColor: "yellowgreen", color: "white" }} />
                        {(localStorage.getItem('role') == "admin") ? <Button variant="contained" onClick={() => deactivate_counting_caisse(props?.data?.id)}>
                            الغاء الموافقة  <Cancel />:
                        </Button> : ""}
                    </>
                    :
                    (props?.value == "transferd") ?
                        <>
                            <Chip sx={{ backgroundColor: "#4173BD", color: "white" }} label="تم التحويل" />
                            {(localStorage.getItem('role') == "admin") ? <Button variant="contained" onClick={() => cancel_transfer_counting_caisse(props?.data?.id)}>
                                الغاء التحويل <CancelOutlined />
                            </Button> : ""}
                        </>
                        : ""
                }
            </Box>
        </>
    )
}

const ValidateComponent = (props) => {
    return (
        <Box sx={{ width: '200px' }}>

            {(localStorage.getItem('role') == "admin") ? <Chip label={(props?.value) ? "تمت الموافقة على التحويل" : "في انتضار الموافقة"} />
                : ""}        </Box>
    )
}



const typeComponent = (props) => {
    return (
      <>
      {props?.value}
      </>
    )
}