import { Box, Center, Flex } from '@chakra-ui/react'
import { Button, Chip } from '@mui/material';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DeleteIcon, EditIcon, ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { Edit } from '@mui/icons-material';
// import DeleteModal from "./deletemodal"
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// import ActionMenu from "./actionmenu"
import currencyFormatter from "currency-formatter"
import { get_Taxpayers } from './utils';
import EditTaxPayer from './EditTaxPayer';
import DeleteTaxPayer from './DeleteTaxPayer';

import supabase from 'src/supabase.config';

var filterParams = {

  // provide comparator function
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;

    if (dateAsString == null) {
      return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('-');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(day, month, year);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

};


const TaxpayerTable = ({ data }) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '80%', direction: "rlt" }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState();

  const TotalPrice = (props) => {
    return (
      <div>
        <Chip label={currencyFormatter.format(props.value, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })} />

      </div>

    )
  }


  const ActionMenu = (props) => {
    console.log(props.value)
    console.log(" i am props")
    return (
      <Box sx={{ display: "flex", justifyContent: 'space-between', width: '100px' }} >
        <EditTaxPayer data={props?.data} />
        <DeleteTaxPayer data={props?.data} />
      </Box>
    )
  }

  const [columnDefs, setColumnDefs] = useState([
    { field: 'last_name', headerName: ' اللقب', filter: "agTextColumnFilter" },
    { field: 'name', headerName: 'الاسم', filter: "agTextColumnFilter" },



    { field: 'id', headerName: 'اجرائات', cellRenderer: ActionMenu, filter: false },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 0.5,
      minWidth: 170,
      filter: true,
      floatingFilter: true,
      filterParams: {
        filterOptions: ['equals']
      }
    };
  }, []);

  useEffect(() => {

    get_Taxpayers(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
      setRowData(res)
    })

  }, [])

   supabase.channel('public:Taxpayers')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'Taxpayers' },
    (payload) => {
      get_Taxpayers(JSON.parse(localStorage.getItem('company_supabase_id'))).then(res => {
        setRowData(res)
      })
    }
  )
  .subscribe()

  return (
    <Center w="auto" h="300px" bg="" shadow={"lg"} dir="ltr" display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="center" >
      <Flex w="100%" h="60px" p={4} color="green.300">

      </Flex>
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableRtl={true}

          ></AgGridReact>
        </div>
      </div>
    </Center>
  )
}

export default TaxpayerTable