import React,{useEffect,useState} from 'react'
import { operation_number_customer } from './utils'
import ArraySom from "array-sum"
import currencyFormatter from "currency-formatter"
import { Box } from '@mui/system'


const CustomerSpending = (customer_id) => {
   const [customerSpending, setcustomerSpending] = useState(0)


    useEffect(() => {
     operation_number_customer(JSON.parse(localStorage.getItem("company_supabase_id")),customer_id).then(res=>{
      setcustomerSpending(ArraySom(res))
      console.log(res)
        console.log(res)
     })
    }, [customer_id,localStorage.getItem("company_supabase_id")])
    
  return (
    <>
 
<Box dir="rtl">
{currencyFormatter.format(customerSpending, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })}
</Box>
   
    </>
  )
}

export default CustomerSpending