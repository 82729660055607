import React from 'react'
import { Page, Text, View, Document, StyleSheet ,Font} from '@react-pdf/renderer';

import Tajwal from "../fonts/tajawal-arabic-400-normal.ttf"
import currencyFormatter from "currency-formatter"


Font.register({
    family: 'Tajwal',
    src: Tajwal
  
  });





// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        borderBottom: "1px solid black",
        height: '30px', width: "100%",
        display:"flex",
        flexDirection: 'row',
      
    },
    colheader: {
        width: '40%',
        height: '30px',
       
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
       
        flexDirection: 'row'
    },
    colheader1: {
        width: '20%',
        height: '30px',
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    colheader3: {
        width: '40%',
        height: '30px',
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
       
        flexDirection: 'row'
    },
    BText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight:'bold',
      
    },
     RText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight:'bold',
        color:'red'
    },
    colhalfrow:{
        width: '60%',
        height: '30px',
      
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
    ,
    colhalfrow2:{
        width: '40%',
        height: '30px',
       
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
});

// Create Document Component
const row = ({design,number,type}) => {
    return (
        <View style={styles.section}>
               <View style={styles.colheader3}>

<Text style={styles.BText}>
   دج  {  currencyFormatter.format(number*design, {
             
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })}
   
</Text>
</View>
<View style={styles.colheader}>
<View style={styles.colhalfrow}>
<Text style={styles.BText}>
  دج  {design}
</Text>
 
</View>
<View style={styles.colhalfrow2}>

<Text style={styles.RText}>
   {type}
</Text>
</View>
 
</View>
<View style={styles.colheader1}>

<Text style={styles.BText}>
   {number}
</Text>
</View>
 
            
        </View>
    )
}

export default row