import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import Tajwal from "../fonts/tajawal-arabic-400-normal.ttf"



Font.register({
    family: 'Tajwal',
    src: Tajwal

});






// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        border: "1px solid black",
        height: '30px', width: "100%",
        display: "flex",
        flexDirection: 'row',
        backgroundColor:'#ffecdc'
    },
    colheader: {
        width: '40%',
        height: '40px',
     
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    colheader1: {
        width: '20%',
        height: '40px',
        borderLeft: "1px solid black",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    BText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight:'bold'
    },
    RText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight:'bold',
        color:'red'
    }

});


const header = () => {
    return (
        <View style={styles.section}>
            <View style={styles.colheader}>

                <Text style={styles.BText}>
                    المجموع
                </Text>
            </View>
            <View style={styles.colheader}>
                <Text style={styles.RText}>
                    الفئة المالية
                </Text>

            </View>
            <View style={styles.colheader1}>
                <Text style={styles.RText}>
                    العدد
                </Text>

            </View>
         
        </View>
    )
}

export default header