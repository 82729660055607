import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Delete, Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { SimpleGrid } from '@chakra-ui/react'
import { delete_customer } from './utils';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 300,
    bgcolor: 'white',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};

export default function DeleteCustomer({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



const Delete_Customer=()=>{
    delete_customer(data?.id).then(res=>{
        handleClose()
    })
}


    return (
        <div>
            <Button onClick={handleOpen}><Delete sx={{ color: "tomato" }} /> </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        مسح الزبون
                    </Typography>
                    <SimpleGrid columns={2} spacing={10}>
                        <TextField id="outlined-basic" label="الاسم" variant="outlined" value={data?.last_name} />
                        <TextField id="outlined-basic" label="اللقب" variant="outlined" value={data?.label} />
                        <TextField id="outlined-basic" label="الشركة" variant="outlined" />
                        <TextField id="outlined-basic" label="رمز الزبون" variant="outlined" value={data?.value} />

                    </SimpleGrid>

                    <Box sx={{ width: "100%", marginTop: 8, height: "30px", display: "flex", justifyContent: "space-between" }}>
                        <Button variant="contained" onClick={Delete_Customer}>
                            مسح  
                        </Button>
                        <Button variant="contained" onClick={ handleClose}>
                            الغاء
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
