import { configureStore } from '@reduxjs/toolkit';
import settingReducer from '../redux/slices';
import globalsettings from "../redux/settings"

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


export const store = configureStore({
  reducer: { settings: settingReducer, globalsettings: globalsettings },
});
