import * as React from 'react';
import {useState} from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { SimpleGrid } from '@chakra-ui/react'
import { TextField } from '@mui/material';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import currencyFormatter from "currency-formatter"
import Payper from "./Payper.png"
import Coin from "./Coin.png"
import ProceddingCountingTab from 'src/components/ProceddingCountingTab';
import { format_sold } from '../conclusion/utils';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 570,
    bgcolor: 'white',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};

















export default function DetailProcedding({data}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [B2000, setB2000] = useState(0)
    const [B1000, setB1000] = useState(0)
    const [B500, setB500] = useState(0)
    const [B200, setB200] = useState(0)


    const [C200, setC200] = useState(0)
    const [C100, setC100] = useState(0)
    const [C50, setC50] = useState(0)
    const [C20, setC20] = useState(0)
    const [C10, setC10] = useState(0)
    const [C5, setC5] = useState(0)
    const [PaperSold, setPaperSold] = useState(0)
    const [CoinSold, setCoinSold] = useState(0)
    const [Sold, setSold] = useState(0)
    const [note, setnote] = useState("")
    return (
        <div>
            <Button onClick={handleOpen} sx={{ width: '100%' }}><PointOfSaleIcon /> تفاصيل المحضر</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        محضر   غلق جزئي
                    </Typography>
              {(data?.type=="غلق جزئي") ?<Box   sx={{width:'100%',height:"400px",backgroundColor:"",marginBottom:2}} >
<SimpleGrid columns={3} spacing={10}>
<TextField id="B2000" label="   مبلغ الغلق الجزئي"  value={format_sold(data?.NetSold)} variant="outlined"   />
                <TextField id="B2000" label="  2000 دج"  value={data?.B2000} variant="outlined"   />



                <TextField id="B1000" label="1000 دج"   value={data?.B1000} variant="outlined"   />



                <TextField id="B500" label="500 دج"   value={data?.B500} variant="outlined"   />



                <TextField id="B200" label="200 دج"  value={data?.B200} variant="outlined"   />



 



                <TextField id="C200" label="200 دج" value={data?.C200}   variant="outlined"   />



                <TextField id="C100" label="100 دج" value={data?.C100}  variant="outlined"   />




                <TextField id="C50" label="50 دج" value={data?.C50}   variant="outlined"   />



                <TextField id="C20" label="20 دج" value={data?.C20}   variant="outlined"  />


 



                <TextField id="C10" label="10 دج"  value={data?.C10} variant="outlined"  />


                <TextField id="C5" label="5 دج"  value={data.C5} variant="outlined"   />





            </SimpleGrid>

</Box>:  <ProceddingCountingTab data={data}/>}

                    <Button variant="contained" onClick={handleClose}>
                        الغاء
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}











// <SimpleGrid columns={2} spacing={10}>
// <TextField id="outlined-basic" label="التاريخ" variant="outlined" value={data?.Date} />

// <TextField id="outlined-basic" label="الساعة" variant="outlined" value={data?.Time.split('.')[0]}  />

// <TextField id="outlined-basic" label="أمين الصندوق" variant="outlined" value={data?.user_name}  />

// <TextField id="outlined-basic" label="المبلغ المحسوب" variant="outlined" value={ currencyFormatter.format(data?.NetSold, {
// symbol: "دج",
// decimal: ",",
// thousand: ".",
// precision: 2,
// format: "%v %s", // %s is the symbol and %v is the value
// })}  />
// <TextField id="outlined-basic" label="المبلغ الورقي" variant="outlined" value={currencyFormatter.format(data?.PaperSold, {
// symbol: "دج",
// decimal: ",",
// thousand: ".",
// precision: 2,
// format: "%v %s", // %s is the symbol and %v is the value
// })}  />
// <TextField id="outlined-basic" label="المبلغ المعدني" variant="outlined" value={currencyFormatter.format(data?.CoinSold, {
// symbol: "دج",
// decimal: ",",
// thousand: ".",
// precision: 2,
// format: "%v %s", // %s is the symbol and %v is the value
// })}  />

// </SimpleGrid>
// <br></br>

// <Tabs variant='enclosed'>
//     <TabList mb={4}>
//         <Tab>
//             <Button variant="contained" sx={{ marginLeft: 2,marginBottom:2 }}>
//                 <img src={Payper} />
//             </Button>
//         </Tab>
//         <Tab> <Button variant="outlined" sx={{ marginLeft: 2,marginBottom:2 }}>
//             <img src={Coin} />
//         </Button></Tab>

//     </TabList>

//     <TabPanels>
//         <TabPanel>
//             <SimpleGrid columns={2} spacing={10}>
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 2000 دج"
//                     value={B2000}
//                     onChange={(e) => setB2000(e.target.value)}
//                     onFocus={(e) => setB2000("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 1000 دج"
//                     value={B1000}
//                     onChange={(e) => setB1000(e.target.value)}
//                     onFocus={(e) => setB1000("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 500 دج"
//                     value={B500}
//                     onChange={(e) => setB500(e.target.value)}
//                     onFocus={(e) => setB500("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 200 دج"
//                     value={B200}
//                     onChange={(e) => setB200(e.target.value)}
//                     onFocus={(e) => setB200("")}
//                 />
//             </SimpleGrid>
//         </TabPanel>
//         <TabPanel>
//             <SimpleGrid columns={2} spacing={10}>
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 200 دج"
//                     value={C200}
//                     onChange={(e) => setC200(e.target.value)}
//                     onFocus={(e) => setC200("")}
//                 />
              
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 100 دج"
//                     value={C100}
//                     onChange={(e) => setC100(e.target.value)}
//                     onFocus={(e) => setC100("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 50 دج"
//                     value={C50}
//                     onChange={(e) => setC50(e.target.value)}
//                     onFocus={(e) => setC50("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 20 دج"
//                     value={C20}
//                     onChange={(e) => setC20(e.target.value)}
//                     onFocus={(e) => setC20("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 10 دج"
//                     value={C10}
//                     onChange={(e) => setC10(e.target.value)}
//                     onFocus={(e) => setC10("")}
//                 />
//                 <TextField

//                     sx={{ width: '140px', direction: "rtl", textAlign: "right" }}
//                     id="outlined-multiline-flexible"
//                     label=" 5 دج"
//                     value={C5}
//                     onChange={(e) => setC5(e.target.value)}
//                     onFocus={(e) => setC5("")}
//                 />
//             </SimpleGrid>
//         </TabPanel>

//     </TabPanels>
// </Tabs>