import supabase from "src/supabase.config";




export const get_Taxpayers = async (caisse) => {
    let { data: Taxpayers, error } = await supabase
        .from('Taxpayers')
        .select('*')
        .eq('caisse', caisse)
    if (Taxpayers) {

        return Taxpayers




    }
}


export const edit_concerned=async(concerned_id,last_name,label)=>{
    console.log(concerned_id)
    
    const { data, error } = await supabase
      .from('Concerneds')
      .update({ last_name: last_name,name: label })
      .eq('id', concerned_id)
      if(error)console.log(error)
}
export const delete_concerned=async(concerned_id)=>{
    const { data, error } = await supabase
    .from('Concerneds')
    .delete()
    .eq('id',concerned_id )
    .select()
    if(data){
        return data
    }
}


export const get_Concerned = async (caisse) => {
    let { data: Concerneds, error } = await supabase
        .from('Concerneds')
        .select('*')
        .eq('caisse', caisse)
    if (Concerneds) {

        return Concerneds




    }
}



export const edit_taxpayer=async(taxpayer_id,last_name,label)=>{
console.log(taxpayer_id)

const { data, error } = await supabase
  .from('Taxpayers')
  .update({ last_name: last_name,name: label })
  .eq('id', taxpayer_id)
  if(error)console.log(error)

}

export const delete_taxpayer=async(taxpayer_id)=>{
    const { data, error } = await supabase
    .from('Taxpayers')
    .delete()
    .eq('id',taxpayer_id )
    .select()
    if(data){
        return data
    }
}


export const get_sections=async(caisse_id)=>{

    let { data: sections, error } = await supabase
    .from('sections')
    .select('*')
    // .eq('caisse',caisse_id)
    if(sections) return sections
  
}

export const get_entrances=async(caisse_id,section_id)=>{

    let { data: entrances, error } = await supabase
    .from('entrances')
    .select('*')
    // .eq('caisse',caisse_id)
    .eq('section',section_id)
    if(entrances) return entrances
  
}


export const get_doors=async(caisse_id,entrance_id)=>{

    let { data: doors, error } = await supabase
    .from('doors')
    .select('*')
    // .eq('caisse',caisse_id)
    .eq('entrance',entrance_id)
    if(doors) return doors
    if(error)console.log(error)
  
}




export const add_new_entrances = async (caisse_id, section_id, name) => {
  const { data, error } = await supabase
    .from("entrances")
    .insert([{ caisse: caisse_id, section: section_id, name: name }])
    .select();
  if (data) return data;
  if (error) console.log(error);
};



export const add_new_door = async (caisse_id, entrance_id, name) => {
    const { data, error } = await supabase
      .from("doors")
      .insert([{ caisse: caisse_id, entrance: entrance_id, name: name }])
      .select();
    if (data) return data;
    if (error) console.log(error);
  };
  

