import supabase from "src/supabase.config";
import { get_open_job_id } from "../income/utils";

// var caisse_id=localStorage.getItem('company_supabase_id')
// var Data_Time=localStorage.getItem('data_time')

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

  today = yyyy + '/' + mm + '/' + dd;



  var Data= [
    {
      "id": 3782,
      "created_at": "2023-04-06T04:57:07.733773+00:00",
      "sold": 0,
      "caisse": 10,
      "Taxpayer": null,
      "Time": "04:57:07.733773",
      "PaperSold": 0,
      "CoinSold": 0,
      "Note": null,
      "Date": "2023-04-06",
      "B2000": 0,
      "B1000": 0,
      "B500": 0,
      "B200": 0,
      "C200": 0,
      "C100": 0,
      "C50": 0,
      "C20": 0,
      "C10": 0,
      "C5": 0,
      "CustomerNumber": null,
      "OperationNumber": null,
      "code": "افتتاح"
    },
    {
      "id": 3783,
      "created_at": "2023-04-06T04:57:22.929741+00:00",
      "sold": 12211,
      "caisse": 10,
      "Taxpayer": "kaddour",
      "Time": "04:57:22.929741",
      "PaperSold": 0,
      "CoinSold": 0,
      "Note": "note",
      "Date": "2023-04-06",
      "B2000": 0,
      "B1000": 0,
      "B500": 0,
      "B200": 0,
      "C200": 0,
      "C100": 0,
      "C50": 0,
      "C20": 0,
      "C10": 0,
      "C5": 0,
      "CustomerNumber": 1,
      "OperationNumber": 1,
      "code": "مدخول"
    }
  ]
export const get_income_caisse = async (caisse_id,job_id) => {
 
    let { data: income_caisse, error } = await supabase
      .from("income_caisse")
      .select("*")
      .eq("caisse", caisse_id)
      .eq("job_id",job_id)
      .eq("Date", today);
    if (income_caisse) {
      console.log("what hte fuck income")
      console.log(income_caisse);
      return income_caisse
    }
 
};
export const get_spend_caisse = async (caisse_id,job_id) => {
  
    let { data: spend_caisse, error } = await supabase
      .from("spend_caisse")
      .select("*")
      .eq("job_id", job_id)
      .eq("Date", today)
      .eq("code", "مصروف")
      .eq("caisse", caisse_id);
    if (spend_caisse) {
      console.log("I am spends");
      console.log(spend_caisse);
      return spend_caisse;
    }
 

}

 


const get_Time_Data = async (caisse_id) => {
    let { data: caisse, error } = await supabase
      .from('caisse_sondok')
      .select('Timedata').eq('id', caisse_id)
    if (caisse) {
      console.log("Time SOndok")
   return caisse[0].Timedata
     
     
    }
    
  }




  function minusOneHour(timeString) {
    console.log(timeString?.split('+')[0])
    const [hours, minutes, seconds] = timeString?.split('+')[0].split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + (seconds) - 3600;
    const newHours = Math.floor(totalSeconds / 3600);
    const newMinutes = Math.floor((totalSeconds - newHours * 3600) / 60);
    const newSeconds = totalSeconds % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
  }
  

  function calculateTotals(data) {
    const { sold, B2000, B1000, B500, B200, C200, C100, C50, C20, C10, C5 } = data;
    const totalSold = sold + (C200 + C100 + C50 + C20 + C10 + C5) / 100;
    const totalDetails = B2000 + B1000 + B500 + B200 + C200 + C100 + C50 + C20 + C10 + C5 / 100;
    return { totalSold, totalDetails };
  }



  export const Total_Incomes=(data)=>{
    const totalSold = data?.reduce((acc, curr) => acc + curr?.sold, 0);
    console.log(totalSold)
const totalDetails =data?.reduce((acc, curr) => {
  acc.B2000 += curr.B2000;
  acc.B1000 += curr.B1000;
  acc.B500 += curr.B500;
  acc.B200 += curr.B200;
  acc.C200 += curr.C200;
  acc.C100 += curr.C100;
  acc.C50 += curr.C50;
  acc.C20 += curr.C20;
  acc.C10 += curr.C10;
  acc.C5 += curr.C5;
  return acc;
}, {
  B2000: 0,
  B1000: 0,
  B500: 0,
  B200: 0,
  C200: 0,
  C100: 0,
  C50: 0,
  C20: 0,
  C10: 0,
  C5: 0,
});

return {totalSold,totalDetails}
  }
  


  export const subtractObjects=(obj1, obj2)=> {
    let result = {};
    if(obj1 && obj2){

   
    for (let key in obj1) {
      result[key] = obj1[key] - obj2[key];
    }
}
    return result;
  }




  export const mini_closing=async(Details,Sold,caisse_id)=>{
console.log(Details)
console.log(Sold)




const { data:incomes_coffre, error } = await supabase
  .from('incomes_coffre')
  .insert([
    { B2000: Details?.B2000, B1000: Details?.B1000,B500: Details?.B500,B200: Details?.B200  ,C200: Details?.C200     ,C100: Details?.C100 ,C50:Details?.C50 ,C20: Details?.C20 ,C10: Details?.C10 ,C5: Details?.C5 ,Date:today,sold: Sold,type:"Mini-Closing",caisse:caisse_id },
  ])
  if(incomes_coffre){
    console.log("mini Closing is Done")
    Update_Time(caisse_id)
    return 1
  }
  if(error) console.log(error)
 

  }

  function minusOneHour2(timeString) {
    console.log(timeString)
    const [hours, minutes, seconds] = timeString?.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + (seconds) - 3600;
    const newHours = Math.floor(totalSeconds / 3600);
    const newMinutes = Math.floor((totalSeconds - newHours * 3600) / 60);
    const newSeconds = totalSeconds % 60;
    return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
  }

  export const Update_Time=async(caisse_id)=>{
    console.log(minusOneHour2(getCurrentTime()))
const { data, error } = await supabase
.from('caisse_sondok')
.update({ Timedata: minusOneHour2(getCurrentTime()) })
.eq('id', caisse_id)
if(error)console.log(error)

  }


  

  let now = new Date(); // creates a new Date object with the current date and time
export const currentTime = now.toLocaleTimeString(); // gets the current time in the user's local time zone


function getCurrentTime() {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();

  // Add leading zeros to minutes and seconds
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  // Format the time as "hh:mm:ss"
  const currentTime = `${hours}:${minutes}:${seconds}`;
  return currentTime;
}


export const aliment_coffre=async(caisse_id,Total_Sold)=>{
  
  const { data:incomes_coffre, error } = await supabase
  .from('incomes_coffre')
  .insert([
    { Date:today,sold: Total_Sold,type:"Mini-Closing",caisse:caisse_id },
  ])
  if(incomes_coffre){
    console.log("mini Closing is Done")
   
    return 1
  }
  if(error) console.log(error)
}


export const Mini_close_as_new_job = async (caisse_id,Total_Sold,TB2000,TB1000,TB500,TB200,TC200,TC100,TC50,TC20,TC10,TC5) => {
  // get id of open one
  let { data: Job_caisse_id, error } = await supabase
    .from("Job_caisse")
    .select("*")
     .eq("open", true)
    .eq("caisse", caisse_id);
  if (Job_caisse_id) {
    console.log(Job_caisse_id)
    insert_mini_close_as_spending(caisse_id,Total_Sold,TB2000,TB1000,TB500,TB200,TC200,TC100,TC50,TC20,TC10,TC5,Job_caisse_id[0]?.id)

}

 
if(error) console.log(error)
 // aliment_coffre(caisse_id,Total_Sold)
  // get id of open one
  // let { data: Job_caisse, error } = await supabase
  //   .from("Job_caisse")
  //   .select("*")
  //   .eq("open", true)
  //   .eq("caisse", caisse_id);
  // if (Job_caisse) {

  //   // close it
  //   const { data, error } = await supabase
  //     .from("Job_caisse")
  //     .update({ open: false })
  //     .eq("id", Job_caisse[0]?.id)
  //     .select()
  //   if (data) {

  //     // then create new one
  //     const { data, error } = await supabase
  //       .from("Job_caisse")
  //       .insert([{ open: true, caisse: caisse_id }]);
  //   }
  // }
};


export const insert_mini_close_as_spending=async(caisse_id,Total_Sold,TB2000,TB1000,TB500,TB200,TC200,TC100,TC50,TC20,TC10,TC5,Job_caisse)=>{
console.log(caisse_id,Total_Sold,TB2000,TB1000,TB500,TB200,TC200,TC100,TC50,TC20,TC10,TC5,Job_caisse)


const { data, error } = await supabase
  .from('spend_caisse')
  .insert([
    { sold: Total_Sold,B2000:TB2000,B1000:TB1000,B500:TB500,B200:TB200,C200:TC200,C100:TC100,C50:TC50,C20:TC20,C10:TC10,C5:TC5,caisse:caisse_id,job_id:Job_caisse,code:"غلق جزئي" },
  ])
  if(error)console.log(error)
 


}


export const Final_Closing=async(caisse_id)=>{
 // get id of open one
 let { data: Job_caisse, error } = await supabase
 .from("Job_caisse")
 .select("*")
 .eq("open", true)
 .eq("caisse", caisse_id);
if (Job_caisse) {

 // close it
 const { data, error } = await supabase
   .from("Job_caisse")
   .update({ open: false })
   .eq("id", Job_caisse[0]?.id)
   .select()
  }
}


// Get the current date and time
const now_Time = new Date();

// Set the desired time to 3 PM
export const desiredTime = new Date(now_Time.getFullYear(), now_Time.getMonth(), now_Time.getDate(), 20, 49, 0);

// Calculate the delay in milliseconds until the desired time
export const delay = desiredTime.getTime() - now.getTime();



// Your function to be executed
function yourFunction() {
  // Code to be executed at 3 PM
  console.log("The time is now 3 PM!");
}


const finalclose = async () => {
  Final_Closing(localStorage.getItem("company_supabase_id")).then(res=>{
  })
}



  // Schedule the function to run after the delay
  setTimeout(finalclose, delay);