import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name:faker.commerce.price(),
  company: faker.commerce.price(),
  isVerified: faker.commerce.price(),
  status: sample(['active', 'banned']),
  role: faker.commerce.price(),
}));
console.log(users)
export default 

[
  {
      "id": "2ec1f886-8d37-40a2-8ad2-01dc4a8240f3",
      "avatarUrl": "/static/mock-images/avatars/avatar_1.jpg",
      "name": "208.00",
      "company": "801.00",
      "isVerified": "47.00",
      "status": "banned",
      "role": "568.00"
  },
  {
      "id": "04efb947-093c-4fe1-88f8-c49098e90f65",
      "avatarUrl": "/static/mock-images/avatars/avatar_2.jpg",
      "name": "312.00",
      "company": "861.00",
      "isVerified": "200.00",
      "status": "banned",
      "role": "681.00"
  },
  {
      "id": "0e5f654d-35a3-4414-aa09-44efc2ef7799",
      "avatarUrl": "/static/mock-images/avatars/avatar_3.jpg",
      "name": "829.00",
      "company": "852.00",
      "isVerified": "71.00",
      "status": "active",
      "role": "98.00"
  },
  {
      "id": "43647766-3dc7-4140-b5b3-593ed5c06ed6",
      "avatarUrl": "/static/mock-images/avatars/avatar_4.jpg",
      "name": "364.00",
      "company": "771.00",
      "isVerified": "606.00",
      "status": "active",
      "role": "853.00"
  },
  {
      "id": "c21a8940-c1db-49d6-9d74-7f71325ac830",
      "avatarUrl": "/static/mock-images/avatars/avatar_5.jpg",
      "name": "128.00",
      "company": "282.00",
      "isVerified": "87.00",
      "status": "banned",
      "role": "860.00"
  },
  {
      "id": "5676fdfc-ad61-446f-aa31-b76e187ea6ba",
      "avatarUrl": "/static/mock-images/avatars/avatar_6.jpg",
      "name": "272.00",
      "company": "561.00",
      "isVerified": "797.00",
      "status": "active",
      "role": "972.00"
  },
  {
      "id": "0888856f-38c0-4860-b1b7-da9b8e470b44",
      "avatarUrl": "/static/mock-images/avatars/avatar_7.jpg",
      "name": "702.00",
      "company": "339.00",
      "isVerified": "363.00",
      "status": "active",
      "role": "964.00"
  },
  {
      "id": "d269bf09-7ef1-4210-b47d-820cbaf183a8",
      "avatarUrl": "/static/mock-images/avatars/avatar_8.jpg",
      "name": "542.00",
      "company": "346.00",
      "isVerified": "157.00",
      "status": "banned",
      "role": "213.00"
  },
  {
      "id": "62b96e8e-16b0-44ad-b58a-38cb7977d341",
      "avatarUrl": "/static/mock-images/avatars/avatar_9.jpg",
      "name": "29.00",
      "company": "439.00",
      "isVerified": "761.00",
      "status": "banned",
      "role": "163.00"
  },
  {
      "id": "5d79bf8a-bbce-4889-b221-77b5faccf0b7",
      "avatarUrl": "/static/mock-images/avatars/avatar_10.jpg",
      "name": "12.00",
      "company": "613.00",
      "isVerified": "807.00",
      "status": "banned",
      "role": "864.00"
  },
  {
      "id": "e872ec57-43b7-4729-afd6-1f6cf413d607",
      "avatarUrl": "/static/mock-images/avatars/avatar_11.jpg",
      "name": "427.00",
      "company": "336.00",
      "isVerified": "880.00",
      "status": "banned",
      "role": "437.00"
  },
  {
      "id": "61ab35ca-e6f4-451f-9627-7e406e96a606",
      "avatarUrl": "/static/mock-images/avatars/avatar_12.jpg",
      "name": "881.00",
      "company": "457.00",
      "isVerified": "389.00",
      "status": "active",
      "role": "31.00"
  },
  {
      "id": "6d1f979e-57b5-42fb-81c4-e2f3931d200f",
      "avatarUrl": "/static/mock-images/avatars/avatar_13.jpg",
      "name": "945.00",
      "company": "909.00",
      "isVerified": "589.00",
      "status": "banned",
      "role": "548.00"
  },
  {
      "id": "36d998a8-23ff-474d-bc59-bb0023c0f5ff",
      "avatarUrl": "/static/mock-images/avatars/avatar_14.jpg",
      "name": "208.00",
      "company": "424.00",
      "isVerified": "21.00",
      "status": "active",
      "role": "28.00"
  },
  {
      "id": "1a8ad52a-aa6f-4afc-9eae-08faf9ee1db1",
      "avatarUrl": "/static/mock-images/avatars/avatar_15.jpg",
      "name": "305.00",
      "company": "626.00",
      "isVerified": "289.00",
      "status": "banned",
      "role": "595.00"
  },
  {
      "id": "fbebc599-d636-48da-a2af-b0c3a8875ebd",
      "avatarUrl": "/static/mock-images/avatars/avatar_16.jpg",
      "name": "308.00",
      "company": "985.00",
      "isVerified": "909.00",
      "status": "active",
      "role": "900.00"
  },
  {
      "id": "2b115ca9-c97c-4161-81d8-7120214f99ce",
      "avatarUrl": "/static/mock-images/avatars/avatar_17.jpg",
      "name": "292.00",
      "company": "565.00",
      "isVerified": "409.00",
      "status": "banned",
      "role": "649.00"
  },
  {
      "id": "4a939333-ad6d-4e98-bfb3-36b57d852cd0",
      "avatarUrl": "/static/mock-images/avatars/avatar_18.jpg",
      "name": "171.00",
      "company": "973.00",
      "isVerified": "967.00",
      "status": "banned",
      "role": "111.00"
  },
  {
      "id": "1fb54410-6049-417b-be92-c0e9051d0121",
      "avatarUrl": "/static/mock-images/avatars/avatar_19.jpg",
      "name": "75.00",
      "company": "94.00",
      "isVerified": "744.00",
      "status": "active",
      "role": "651.00"
  },
  {
      "id": "9231945f-5ec1-421d-b3bc-1c66a09640f1",
      "avatarUrl": "/static/mock-images/avatars/avatar_20.jpg",
      "name": "319.00",
      "company": "435.00",
      "isVerified": "969.00",
      "status": "banned",
      "role": "929.00"
  },
  {
      "id": "063e4cd1-ac4d-4578-b5d0-1abfabdb1718",
      "avatarUrl": "/static/mock-images/avatars/avatar_21.jpg",
      "name": "832.00",
      "company": "296.00",
      "isVerified": "281.00",
      "status": "banned",
      "role": "4.00"
  },
  {
      "id": "4b2b1ad2-b72c-44df-95cd-ff681a29eb98",
      "avatarUrl": "/static/mock-images/avatars/avatar_22.jpg",
      "name": "24.00",
      "company": "555.00",
      "isVerified": "172.00",
      "status": "active",
      "role": "292.00"
  },
  {
      "id": "7aba2be6-34b7-402d-a429-cbade537958e",
      "avatarUrl": "/static/mock-images/avatars/avatar_23.jpg",
      "name": "335.00",
      "company": "636.00",
      "isVerified": "927.00",
      "status": "active",
      "role": "71.00"
  },
  {
      "id": "f65007b0-4bad-4e6f-b62e-bccfe2d1e90e",
      "avatarUrl": "/static/mock-images/avatars/avatar_24.jpg",
      "name": "949.00",
      "company": "421.00",
      "isVerified": "436.00",
      "status": "banned",
      "role": "617.00"
  }
];
