import supabase from "src/supabase.config";
import { todaydate } from "src/utils/formatTime";
import { get_report_data } from "../conclusion/helpers/helpers";




export const get_id_counting=async()=>{

}




export const create_counting_caisse=async(user,caisse)=>{
// get all operation reports
get_report_data(todaydate,caisse).then(async res=>{
  console.log(res)
     const { data, error } = await supabase
    .from('proceedings_couting_caisse')
    .insert([
      { caisse: caisse, NetSold: res[0].TotalNet,user_name:user,B2000:res[0].TotalB2000,B1000:res[0].TotalB1000,B500:res[0].TotalB500,B200:res[0].TotalB200,C200:res[0].TotalC200,C100:res[0].TotalC100,C50:res[0].TotalC50,C20:res[0].TotalC20,C10:res[0].TotalC10,C5:res[0].TotalC5,state:"created" },
    ])
    if(error)console.log(error)
})







// insert this report into proceding counting table

// admin validate this report

// user cant change

// admin send this report to coffre

// coffre admin validate

// report  insert into coffre in alimentation with that caisse


    // const { data, error } = await supabase
    // .from('proceedings_couting_caisse')
    // .insert([
    //   { caisse: 10, NetSold: 32314,user_name:"asma" },
    // ])
    // if(error)console.log(error)
}


export const activate_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'approved' })
  .eq('id', proceding_id)

}


export const deactivate_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'created' })
  .eq('id', proceding_id)

}


export const transfer_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'transferd' })
  .eq('id', proceding_id)

}

export const cancel_transfer_counting_caisse=async(proceding_id)=>{
  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .update({ state: 'approved' })
  .eq('id', proceding_id)

}



export const get_new_proceeding=async(caisse)=>{

  let { data: proceedings_couting_caisse, error } = await supabase
  .from('proceedings_couting_caisse')
  .select('*state')
  .eq('state',"created")
  .eq("caisse",caisse)
  if(proceedings_couting_caisse) return proceedings_couting_caisse.length

}



export const Delete_proceeding=async(proceeding_id)=>{

  const { data, error } = await supabase
  .from('proceedings_couting_caisse')
  .delete()
  .eq('id', proceeding_id)
  .select()
  if(data)return 1

}