import * as React from 'react';
import {useEffect,useState} from 'react'
import axios from 'axios'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import currencyFormatter from "currency-formatter"
import { IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { baseurl } from 'src/utils/constant';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables({ incomeData,year,month }) {
  const [open, setOpen] = React.useState(false);
  const [reportdata, setreportdata] = useState([])
   console.log((year)+"-"+(month+1)+"-"+(Date))
  const username = JSON.parse(localStorage.getItem("log"))?.username;
  const password = JSON.parse(localStorage.getItem("log"))?.password;
  useEffect(() => {
    axios.post(baseurl+'/income/today', {
      "username": username,
      "password": password,
      "company_id": Number(JSON.parse(localStorage.getItem("company_id"))),
      "date": (year)+"-"+(month+1)+"-"+(Date)
      // (year)+"-"+(month+1)+"-"+(Date)
    })
      .then(function (response) {
        setreportdata(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>التاريخ</StyledTableCell>
            <StyledTableCell align="right">    الساعة </StyledTableCell>
            <StyledTableCell align="right">رمز الزبون</StyledTableCell>
            <StyledTableCell align="right">العملية</StyledTableCell>
            <StyledTableCell align="right">المبلغ</StyledTableCell>
            <StyledTableCell align="right">عمليات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportdata?.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row.date}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.time}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.client_code}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.operation_code}
              </StyledTableCell>
              <StyledTableCell align="right">
                {currencyFormatter.format(row.balance, {
                  symbol: "دج",
                  decimal: ",",
                  thousand: ".",
                  precision: 2,
                  format: "%v %s", // %s is the symbol and %v is the value
                })}

              </StyledTableCell>
              <StyledTableCell align="center">
                <Stack direction="row" spacing={1}>
                  <IconButton aria-label="delete" sx={{ color: "tomato" }} >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton aria-label="delete" sx={{ color: "yellowgreen" }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <LocalPrintshopIcon />
                  </IconButton>
                </Stack>

              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
