import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DeleteIcon } from '@chakra-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SimpleGrid, Text } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { Chip, TextField } from '@mui/material';
import currencyFormatter from "currency-formatter"
import DetailsTab from 'src/components/DetailsTab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'auto',
    bgcolor: 'white',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

export default function ShowSpend({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    console.log(data)
    console.log('hey hye hye hey')
    return (
        <div>
            <Button onClick={handleOpen}> <VisibilityIcon />  </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                <Chip sx={{backgroundColor:"tomato",color:"white",marginBottom:2}} label={data?.code}/>

                    <SimpleGrid columns={2} spacing={10}>
                        <TextField id="outlined-basic" label="التاريخ" variant="outlined" value={data?.Date} />
                        <TextField id="outlined-basic" label="المبلغ الورقي" variant="outlined" value={currencyFormatter.format(data?.PaperSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}
                     
                        
                        />
                        
                        <TextField id="outlined-basic" label="المبلغ المعدني" variant="outlined" value={currencyFormatter.format(data?.CoinSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}/>
                        <TextField id="outlined-basic" label="المبلغ الاجمالي" variant="outlined" value={currencyFormatter.format(data?.sold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })} />
                        <TextField id="outlined-basic" label="المكلف" variant="outlined" value={data?.Concerned} />
                        <TextField id="outlined-basic" label="المعني" variant="outlined" value={data?.Taxpayer} />
                    </SimpleGrid>
<DetailsTab data={data} />

                    <Button variant="contained" onClick={handleClose}>
                        خروج
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
