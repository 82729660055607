import supabase from "src/supabase.config";

var distinct = require('distinct-array');
const uniqueObjects = require('unique-objects');

export const get_concerned_spend = async (FromDate, ToDate, concerned,caisse) => {

    if (concerned != "") {
        let { data: spend_caisse, error } = await supabase
            .from('spend_caisse')
            .select('id,Date,sold,Time,Concerned,Taxpayer,Door,Section,Entrance,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
            // .eq('Date', (year) + "-" + (month + 1) + "-" + (Day))
            .gte('Date', FromDate)
            .lte('Date', ToDate)
            .eq('caisse',caisse)
            .eq("Concerned", concerned)
        if (spend_caisse) {

            let TotalSpend = 0
            for (let index = 0; index < spend_caisse.length; index++) {
                TotalSpend = spend_caisse[index].sold + TotalSpend

            }
            return {TotalSpend:TotalSpend,Data:spend_caisse}
        }
    }
    if (concerned == "") {
        let { data: spend_caisse, error } = await supabase
            .from('spend_caisse')
            .select('id,Date,sold,Time,Concerned,Taxpayer,Door,Section,Entrance,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')

            .gte('Date', FromDate)
            .lte('Date', ToDate)
            .eq('caisse', localStorage.getItem('company_supabase_id'))
        if (spend_caisse) {

            let TotalSpend = 0
            for (let index = 0; index < spend_caisse.length; index++) {
                TotalSpend = spend_caisse[index].sold + TotalSpend

            }
            return {TotalSpend:TotalSpend,Data:spend_caisse}
        }
    }


}




export const get_filtered_income = async (FromDate, ToDate,caisse) => {
console.log(FromDate)
console.log(ToDate)

    let { data: income_caisse, error } = await supabase
        .from('income_caisse')
        .select('id,Date,sold,CustomerNumber,OperationNumber,Time,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
        .gte('Date', FromDate)
        .lte('Date', ToDate)
        .eq('caisse',caisse)


    if (income_caisse) {

        let TotalIncome = 0
        for (let index = 0; index < income_caisse.length; index++) {
            TotalIncome = income_caisse[index].sold + TotalIncome

        }
        console.log(TotalIncome)
        return { TotalIncome: TotalIncome, Data: income_caisse }

    }


  
}


export const get_filtered_customer_income = async (FromDate, ToDate,caisse,customer_id) => {
  console.log(customer_id)
  console.log(customer_id)
  
      let { data: income_caisse, error } = await supabase
          .from('income_caisse')
          .select('id,Date,sold,CustomerNumber,OperationNumber,Time,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
          .gte('Date', FromDate)
          .lte('Date', ToDate)
          .eq('caisse',caisse)
          .eq("CustomerNumber",customer_id)

  
  
      if (income_caisse) {
  
          let TotalIncome = 0
          for (let index = 0; index < income_caisse.length; index++) {
              TotalIncome = income_caisse[index]?.sold + TotalIncome
  
          }
          console.log(income_caisse)
          return { TotalIncome: TotalIncome, Data: income_caisse }
  
      }
  
  
    
  }


export const get_all_concerned=async(caisse)=>{
    let { data: spend_caisse, error } = await supabase
    .from('spend_caisse')
    .select('Concerned')
    .eq('caisse', caisse)


if (spend_caisse) { 
return uniqueObjects(spend_caisse, ['Concerned'])

}
}

export const get_customers = async (caisse) => {
  if(caisse==1){


  let customerarray = []
  let { data: customers, error } = await supabase
    .from('customers')
    .select('*')
     
  if (customers)
    for (let index = 0; index < customers.length; index++) {

      customerarray.push({ label: customers[index].label, value: customers[index].value })
    }
  return customerarray
}
else{
  
  let customerarray = []
  let { data: customers, error } = await supabase
    .from('CustomersGros')
    .select('*')
    .eq('caisse', caisse)
  if (customers)
    for (let index = 0; index < customers.length; index++) {

      customerarray.push({ label: customers[index].label, value: customers[index].value })
    }
  return customerarray
}
}
 