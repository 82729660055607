
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DeleteIcon } from '@chakra-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SimpleGrid, Text } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { Chip, TextField } from '@mui/material';
import currencyFormatter from "currency-formatter"
import DetailsTab from 'src/components/DetailsTab';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 600,
  bgcolor: 'white',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function Show({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data)
  console.log('hey hye hye hey')
  return (
    <div>
      <Button onClick={handleOpen} sx={{width:'100%',dir:"rtl",display:"flex",justifyContent:"flex-start",paddingLeft:'20px'}}> <VisibilityIcon />  عرض </Button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          
          <Chip sx={{backgroundColor:"yellowgreen",color:"white",marginBottom:2}}  label={data?.code}/>

          <SimpleGrid columns={2} spacing={10}>
            <TextField id="outlined-basic" label="التاريخ" variant="outlined" value={data?.Date} />
            <TextField id="outlined-basic" label="المبلغ الورقي" variant="outlined" value={currencyFormatter.format(data?.PaperSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}
                     
                        
                        />
                        <TextField id="outlined-basic" label="المبلغ المعدني" variant="outlined" value={currencyFormatter.format(data?.CoinSold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })}/>
                        <TextField id="outlined-basic" label="المبلغ الاجمالي" variant="outlined" value={currencyFormatter.format(data?.sold, {
                            symbol: "دج",
                            decimal: ",",
                            thousand: ".",
                            precision: 2,
                            format: "%v %s", // %s is the symbol and %v is the value
                          })} />
            <TextField id="outlined-basic" label="المكلف" variant="outlined" value={data?.Taxpayer} />

            <TextField id="outlined-basic" label="رمز الزبون" variant="outlined" value={data?.CustomerNumber} />
          </SimpleGrid>
                          <DetailsTab data={data}/>

          <Button variant="contained"onClick={handleClose}>
            خروج
          </Button>
        </Box>
      </Modal>
    </div>
  );
}










































// import * as React from 'react';
// import {useState,useEffect} from 'react'
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import Iconify from '../../components/Iconify';
// import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, Container, TextField } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import currencyFormatter from "currency-formatter"
// import { OutboundSharp } from '@mui/icons-material';
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 850,
//   height:550,
//   bgcolor: 'white',
//   borderRadius:1,
//   boxShadow: 24,
//   paddingTop: 14,
//   paddingLeft:10,
//   paddingRight:10,
//   paddingBottom:10,
// };

// export default function BasicModal({ data }) {
//   console.log("data fking incom")
//   console.log(data)
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
// const [operation, setoperation] = useState("")
//   useEffect(() => {
   

//     if(data.operation_code =="1"){
//       setoperation("مخالصة")
//     }
//     if(data.operation_code =="2"){
//       setoperation("قسط")
//     }
//     if(data.operation_code =="3"){
//       setoperation("اخرى")
//     }
    
//   }, []);

//   return (
//     <div >
//       {/* eva:trash-2-outline */}
//       <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary', width: "100%" }}>

//         <ListItemIcon>
//           <Iconify icon="eva:eye-outline" width={24} height={24} />
//         </ListItemIcon>
//         <ListItemText onClick={handleOpen} primary="عرض" primaryTypographyProps={{ variant: 'body2' }} />
//       </MenuItem>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style} dir="rtl">
//           <Grid container spacing={2}>
//           <Grid item xs={6}>
//           <TextField
//                 value={data.created_at.split('T')[0]}
              
//                 id="outlined-helperText"
//                 label="التاريخ"
//                 defaultValue="Default Value"
             
//               />
//           </Grid>
//           <Grid item xs={6}>
//           <TextField
//                  value={data.created_at.split('T')[1].split('.')[0]}
              
//                 id="outlined-helperText"
//                 label="الساعة"
//                 defaultValue="Default Value"
             
//               />
//             </Grid>
//             <Grid item xs={6}>

//               <TextField
//                 value={  currencyFormatter.format(data.sold, {
//                   symbol: "دج",
//                   decimal: ",",
//                   thousand: ".",
//                   precision: 2,
//                   format: "%v %s", // %s is the symbol and %v is the value
//                 })}
              
//                 id="outlined-helperText"
//                 label="المبلغ"
//                 defaultValue="Default Value"
             
//               />
//             </Grid>
           
//             <Grid item xs={6}>
              
//               <TextField
//                 value={data?.Taxpayer
//                 }
              
//                 id="outlined-helperText"
//                 label="المكلف"
//                 defaultValue="Default Value"
             
//               />
//             </Grid>
//             <Grid item xs={6}>
          
//               <TextField
//                 value={  currencyFormatter.format(data.PaperSold, {
//                   symbol: "دج",
//                   decimal: ",",
//                   thousand: ".",
//                   precision: 2,
//                   format: "%v %s", // %s is the symbol and %v is the value
//                 })}
              
//                 id="outlined-helperText"
//                 label="    المبلغ الورقي"
//                 defaultValue="Default Value"
             
//               />
//             </Grid>
//             <Grid item xs={6}>
//             <TextField
//                 value={  currencyFormatter.format(data.CoinSold, {
//                   symbol: "دج",
//                   decimal: ",",
//                   thousand: ".",
//                   precision: 2,
//                   format: "%v %s", // %s is the symbol and %v is the value
//                 })}
              
//                 id="outlined-helperText"
//                 label="        المبلغ المعدني"
//                 defaultValue="Default Value"
             
//               />
       
//             </Grid>
//             <Grid item xs={6}>
//             <TextField
//                 value={ data.CustomerNumber}
              
//                 id="outlined-helperText"
//                 label="          رمز الزبون"
//                 defaultValue="Default Value"
             
//               />
         
//             </Grid>
//             <Grid item xs={6}>
              
//               <TextField
//                 value={data.OperationNumber}
              
//                 id="outlined-helperText"
//                 label="        العملية"
//                 defaultValue="Default Value"
             
//               />
//             </Grid>
//           </Grid>

//           <Box sx={{ display: "flex", height: '180px', width: "100%", justifyContent: "center", alignItems: "center" }}>

//             <Button variant="contained" onClick={handleClose}>   خروج   <OutboundSharp/> </Button>
//           </Box>
      
      
//         </Box>
//       </Modal>
//     </div>
//   );
// }
