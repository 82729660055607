import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import supabase from "src/supabase.config";
import ConcernedTable from "./ConcernedTable";
import TaxpayerTable from "./TaxpayerTable";
import MenuItem from "@mui/material/MenuItem";
import { get_doors, get_entrances, get_sections } from "./utils";
import NewEntranceModal from "./NewEntranceModal";
import NewDoorModal from "./NewDoorModal";
const Settings = () => {
  const [section_id, setsection_id] = React.useState("");
  const [entrance_id, setentrance_id] = useState("");
  const [door_id, setdoor_id] = useState("")
  const [Sections, setSections] = useState([]);
  const [Entrances, setEntrances] = useState([]);
  const [Doors, setDoors] = useState([])
  const handleChange = (event) => {
    setsection_id(event.target.value);
  };

  const handleEntrance = (event) => {
    setentrance_id(event.target.value);
  };

  const handleDoors = (event) => {
    setdoor_id(event.target.value);
  };
  const [name, setname] = useState("");
  const [last_name, setlast_name] = useState("");
  const [sending, setsending] = useState(false);
  const [sending2, setsending2] = useState(false);
  const add_Taxpayer = async () => {
    setsending(true);
    const { data, error } = await supabase
      .from("Taxpayers")
      .insert([
        {
          name: name,
          last_name: last_name,
          caisse: JSON.parse(localStorage.getItem("company_supabase_id")),
        },
      ])
      .select();
    if (error) console.log(error);
    if (data) {
      setname("");
      setlast_name("");
      setsending(false);
    }
  };

  const add_Concernd = async () => {
    setsending2(true);
    const { data, error } = await supabase
      .from("Concerneds")
      .insert([
        {
          name: name,
          last_name: last_name,
          caisse: JSON.parse(localStorage.getItem("company_supabase_id")),
        },
      ])
      .select();
    if (error) console.log(error);
    if (data) {
      setname("");
      setlast_name("aziz");
      setsending2(false);
    }
  };

  useEffect(() => {
    get_sections(localStorage.getItem('company_supabase_id')).then((res) => {
      setSections(res);
    });
    get_entrances(localStorage.getItem('company_supabase_id'), section_id).then((res) => {
      setEntrances(res);
    });

    get_doors(localStorage.getItem('company_supabase_id'), entrance_id).then((res) => {
      console.log(res)
      setDoors(res);
    });
  }, [section_id,entrance_id]);

  const entrances = supabase.channel('custom-all-channel')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'entrances' },
    (payload) => {
      get_sections(localStorage.getItem('company_supabase_id')).then((res) => {
        setSections(res);
      });
      get_entrances(localStorage.getItem('company_supabase_id'), section_id).then((res) => {
        setEntrances(res);
      });
    }
  )
  .subscribe()
  return (
    <Box
      sx={{
        width: "100%",
        height: "500px",
        backgroundColor: "",
        direction: "rtl",
      }}
    >
      <Typography>اضافة مكلف</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={name}
            onChange={(e) => setname(e.target.value)}
            label="الاسم"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={last_name}
            onChange={(e) => setlast_name(e.target.value)}
            label="اللقب"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={sending}
            onClick={add_Taxpayer}
            variant="contained"
          >
            اضافة مكلف <Add />
          </LoadingButton>
        </Grid>
      </Grid>
      <TaxpayerTable />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Typography>اضافة معني</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={name}
            onChange={(e) => setname(e.target.value)}
            label="الاسم"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={last_name}
            onChange={(e) => setlast_name(e.target.value)}
            label="اللقب"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={sending2}
            onClick={add_Concernd}
            variant="contained"
          >
            اضافة معني <Add />
          </LoadingButton>
        </Grid>
      </Grid>
      <ConcernedTable />

      <br></br>
      <Typography>اضافة قسم</Typography>
      <Box
        sx={{
          width: "100%",
          height: "120px",
          backgroundColor: "",
          direction: "rtl",
          border: "1px solid blue",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">جميع الأقسام</InputLabel>
         
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={section_id}
            label="section"
            onChange={handleChange}
          >
            {Sections?.map((section) => {
              return (
                <MenuItem key={section?.id} value={section?.id}>
                  {" "}
                  {section?.name}{" "}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Typography>اضافة المداخل</Typography>
    <NewEntranceModal section_id={section_id} />
      <Box
        sx={{
          width: "100%",
          height: "120px",
          backgroundColor: "",
          direction: "rtl",
          border: "1px solid blue",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">جميع المداخل</InputLabel>
        
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={entrance_id}
            label="section"
            onChange={handleEntrance}
          >
            {Entrances?.map((entrance) => {
              return (
                <MenuItem key={entrance?.id} value={entrance?.id}>
                
                  {entrance?.name}{" "}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>


      <Typography>اضافة الأبواب</Typography>
    <NewDoorModal entrance_id={entrance_id}/>
      <Box
        sx={{
          width: "100%",
          height: "120px",
          backgroundColor: "",
          direction: "rtl",
          border: "1px solid blue",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">جميع الأبواب</InputLabel>
         
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={door_id}
            label="door"
            onChange={handleDoors}
          >
            {Doors?.map((door) => {
              return (
                <MenuItem key={door?.id} value={door?.id}>
                
                  {door?.name} 
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Settings;
