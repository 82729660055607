import * as React from 'react';
import {useState} from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { add_new_entrances } from './utils';
import { Flex } from '@chakra-ui/react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function NewEntranceModal({section_id}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
const [entrance_name, setentrance_name] = useState("")



const add_new=()=>{
    add_new_entrances(localStorage.getItem('company_supabase_id'),section_id,entrance_name).then(_=>{
        handleClose()
    })
}
  return (
    <div>
      

          <Button onClick={handleOpen} variant="contained">
        +اضافة مدخل
      </Button> 
    
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          اضافة مدخل
          </Typography>
        <Flex w="100%" justifyContent={"space-between"}>
        <TextField    value={entrance_name} onChange={(e)=>setentrance_name(e.target.value)}  />


<Button variant="contained" onClick={add_new}>
    +
</Button>
        </Flex>
      
        </Box>
      </Modal>
    </div>
  );
}
