import { Box } from '@mui/system'
import React from 'react'
import { format_sold } from '../conclusion/utils'
import "./Table.css"
import { toArabicWord } from 'number-to-arabic-words/dist/index-node.js';
const Table = ({data}) => {
  return (
    <> 

      <table dir='rtl' style={{ width: '700px',fontFamily:"arial, sans-serif" }}>

        <tr>
          <td className='my_td' align='center'>بتاريـخ يـــوم</td>
          <td className='my_td'> {data?.Date} </td>
          <td className='my_td'>ساعـــة الــيـوم</td>
          <td className='my_td'>{data?.Time.split('.')[0]}</td>
        </tr>



        <tr>
          <td className='my_td'>رقـم المحـضر</td>
          <td className='my_td'>N°: 93</td>
          <td className='my_td'>مرجع المحـضر</td>
          <td className='my_td'>N°: {data?.id}</td>
        </tr>


        <tr>
          <td className='my_td'>لـقـب و إســـم
            محـرر المحضر</td>
          <td className='my_td'> {localStorage.getItem('username')}</td>
          <td className='my_td'>صفة و مـنصب
            محـرر المحضر</td>
          <td className='my_td'>
            {localStorage.getItem('post_name')}
          </td>
        </tr>


        <tr>
          <td className='my_td'>تــم إيداع المبلغ المعاين والمتأكد من صحة حسابه المالي فـــــي الخزينة بالأرقــــــــام </td>
          <td className='my_td'>{format_sold(data?.NetSold)}</td>
          <td className='my_td'>تــم إيداع المبلغ المعاين والمتأكد من صحة حسابه المالي فـــــي الخزينة بالحروف</td>
          <td className='my_td'>           {toArabicWord(data?.NetSold)}  دينار جزائري    </td>
        </tr>






      </table>
      <Box sx={{ width: '100%', display: "flex", justifyContent: "center", }}>
        <Box sx={{ width: '84%', height: '40px', border: "1px solid gray", display: "flex", justifyContent: "flex-start" }}>
          مــلاحـــق الـمـحـضــر
        </Box>

      </Box>
      <Box sx={{ width: '100%', display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: '84%', height: '40px', border: "1px solid gray", display: "flex", justifyContent: "center" }}>
          1. تفاصيل الرصيد المالي اليومي للصندوق الموضح عـدد الأوراق النقـدية و النقود المعـدنية
        </Box>

      </Box>

      <Box sx={{ width: '100%', height: '40px', display: "flex", justifyContent: "center" }}>

        <Box sx={{ width: '84%', height: '40px', border: "1px solid gray", display: "flex", justifyContent: "center" }}>
          تفاصيل جـدول الـنـفـقات و المصاريف الـيـومية
        </Box>

      </Box>


      <Box sx={{ width: '100%', display: "flex", justifyContent: "flex-end", marginTop: 8 }}>
        خـتـم و تـوقـيع أمـيـن الصـنـدوق
      </Box>
    </>
  )
}

export default Table