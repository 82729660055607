import supabase from 'src/supabase.config';
import currencyFormatter from "currency-formatter"
import { toExcel } from 'to-excel';
var fs = require('fs');


export const get_income=async(caisse,startDate,endDate)=>{
    let { data: income_caisse, error } = await supabase
    .from('income_caisse')
    .select('*')
    .eq('caisse',caisse)
    .gte('created_at',startDate+" 05:00:00.000Z")
    .lte('created_at',endDate+" 23:59:59.999Z")
    .order('id', { ascending: false })
    if(income_caisse)return income_caisse
}

export const get_spend=async(caisse,startDate,endDate)=>{
    let { data: spend_caisse, error } = await supabase
    .from('spend_caisse')
    .select('*')
    .eq('caisse',caisse)
    .gte('created_at',startDate+" 05:00:00.000Z")
    .lte('created_at',endDate+" 23:59:59.999Z")
    if(spend_caisse)return spend_caisse
}

export const calculate_Spending=async(data)=>{
    let Total_Spending=0
for (let index = 0; index < data.length; index++) {
    Total_Spending=data[index].sold+Total_Spending
	 
}
return Total_Spending
}





export const Download_Excel_income = (data) => {
    // set Income data
    // var data = [
    //     { id: 1, value: 'Item 1 <br>', status: { item: '가지마' } },
    //     { value: 'Item 2', status: { item: 'благодарю вас' } },
    //     { value: 'Item 3 \'quotes\'', id: 3, status: { item: 'Enabled' } },
    //     { id: 4, value: 'Item 4 "quotes"', extra: 'ignored field' }
    // ];

    // set headers
    var headers = [
        { label: 'التاريخ', field: 'Date' },
        { label: 'المبلغ الورقي', field: 'PaperSold' },
        { label: 'المبلغ المعدني', field: 'CoinSold' },
        { label: 'المبلغ الاجمالي', field: 'sold' },
        { label: ' نوع العملية', field: 'code' },
        { label: ' رمز العملية', field: 'OperationNumber' },
        { label: ' رمز الزبون', field: 'CustomerNumber' },
        { label: '  المكلف', field: 'Taxpayer' }
    ]

    // generate excel file (will download 'filename.xls' from browser)
    var content = toExcel.exportXLS(headers, data, 'المداخيل');
    // in node you must open or save the content
    require('fs').writeFileSync('المداخيل.xls', content);
}

export const Download_Excel_spend = (data) => {
   // set Income data
    // var data = [
    //     { id: 1, value: 'Item 1 <br>', status: { item: '가지마' } },
    //     { value: 'Item 2', status: { item: 'благодарю вас' } },
    //     { value: 'Item 3 \'quotes\'', id: 3, status: { item: 'Enabled' } },
    //     { id: 4, value: 'Item 4 "quotes"', extra: 'ignored field' }
    // ];

    // set headers
    var headers = [
        { label: 'التاريخ', field: 'Date' },
        { label: 'المبلغ الورقي', field: 'PaperSold' },
        { label: 'المبلغ المعدني', field: 'CoinSold' },
        { label: 'المبلغ الاجمالي', field: 'sold' },
        { label: '  القسم', field: 'Door' },
        { label: '  المدخل', field: 'Section' },
        { label: '  الباب', field: 'Entrance' },
        { label: '  المكلف', field: 'Taxpayer' }
    ]

    // generate excel file (will download 'filename.xls' from browser)
    var content = toExcel.exportXLS(headers, data, 'المصاريف');
    // in node you must open or save the content
    require('fs').writeFileSync('المصاريف.xls', content);
}


export const Download_Excel_Report=(data)=>{
    console.log(data)
 // set headers
 var headers = [
    { label: 'التاريخ', field: 'Date' },
    { label: ' مبلغ المداخيل', field: 'TotalIncome' },
    { label: ' مبلغ المصاريف', field: 'TotalSpend' },
    { label: ' المبلغ الصافي', field: 'TotalNet' },
    { label: '  ورق 2000 دج', field: 'TotalB2000' },
    { label: '  ورق 1000 دج ', field: 'TotalB1000' },
    { label: '  ورق 500 دج', field: 'TotalB500' },
    { label: '  ورق 200 دج ', field: 'TotalB200' },

    { label: '       معدن 200 دج ', field: 'TotalC200' },
    { label: '       معدن 100 دج ', field: 'TotalC100' },
    { label: '    معدن 50 دج     ', field: 'TotalC50' },
    { label: '     معدن 20 دج   ', field: 'TotalC20' },
    { label: '   معدن 10 دج     ', field: 'TotalC10' },
    { label: '     معدن 5 دج   ', field: 'TotalC5' }
]

// generate excel file (will download 'filename.xls' from browser)
var content = toExcel.exportXLS(headers, data, 'تقرير');
// in node you must open or save the content
require('fs').writeFileSync('تقرير.xls', content);
}






export const format_sold=(sold)=>{
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })
}

export const format_sold_rtl=(sold)=>{
    return currencyFormatter.format(sold, {
        symbol: "",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v ", // %s is the symbol and %v is the value
      })
}

export const currency="دج"