import * as XLSX from "xlsx";
import fetch from "node-fetch";

/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
import { get_customer_info } from "src/pages/income/utils";
import supabase from "src/supabase.config";
import { currency, format_sold, format_sold_rtl } from "src/pages/conclusion/utils";
XLSX.set_fs(fs);


export const Exceltify_json = async (data) => {
   console.log(data)
 var customerArray = await Map_Customer_Number();

 
  

  /* flatten objects */
   

  const rows = data.map((row) => ({
    Date: row.Date,
    Sold:format_sold(row.sold),
    Door:row.Door,
    Entrance:row.Entrance,
    Section:row.Section,
    Concerned:row.Concerned,
    Taxpayer:row.Taxpayer,

  
    B2000: row.B2000,
    B1000: row.B1000,
    B500: row.B500,
    B200: row.B200,

    C200: row.C200,
    C100: row.C100,
    C50: row.C50,
    C20: row.C20,
    C10: row.C10,
    C5: row.C5,
  }));
  console.log(rows)

  /* generate worksheet and workbook */
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  var wb = { Workbook: { Views: [{ RTL: true }] }, Sheets: {}, SheetNames: [] };

  XLSX.utils.book_append_sheet(wb, worksheet, "Dates");

  /* fix headers */
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "التاريخ",
        "المبلغ",
        "القسم",
        "المدخل",
        "الباب",
        "المعني",
        "المكلف",
        "ورقة 2000",
        "ورقة 2000",
        "ورقة 1000",
        "ورقة 500",
        "معدن 200",
        "معدن 100",
        "معدن 50",
        "معدن 20",
        "معدن 10",
        "معدن 5",
      ],
    ],
    { origin: "A1" }
  );

  /* calculate column width */
  //  const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
  //  worksheet["!cols"] = [ { wch: max_width } ];

  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(wb, "المصاريف.xlsx", { compression: true });
};



const Map_Customer_Number=async ()=>{
  
    const companySupabaseId = JSON.parse(localStorage.getItem('company_supabase_id'));
    if(companySupabaseId==1){
      let { data: customers, error } = await supabase
      .from('customers')
      .select('*')
     if(customers) return  customers
      if(error)console.log(error)
  }
  else{
      let { data: CustomersGros, error } = await supabase
      .from('CustomersGros')
      .select('*')
      .eq('caisse',companySupabaseId)
      if(CustomersGros) 
     {
      console.log(CustomersGros) 
      return  CustomersGros
    }
      if(error)console.log(error)
  }
   
}



export const Exceltify_Incomes_json = async (data) => {
var customerArray = await Map_Customer_Number();


// console.log(customerArray.filter(item=>item.value==43)[0].last_name+" "+customerArray.filter(item=>item.value==43)[0].label)


 /* flatten objects */
  

 const rows = data.map((row) => ({
   Date: row.Date,
   Sold:currency+ format_sold_rtl(row.sold),
   CustomerNumber:customerArray.filter(item=>item.value==row.CustomerNumber)[0]?.last_name+" "+customerArray.filter(item=>item.value==row.CustomerNumber)[0]?.label,
  
   Concerned:row.Concerned,
   Taxpayer:row.Taxpayer,

 
   B2000: row.B2000,
   B1000: row.B1000,
   B500: row.B500,
   B200: row.B200,

   C200: row.C200,
   C100: row.C100,
   C50: row.C50,
   C20: row.C20,
   C10: row.C10,
   C5: row.C5,
 }));
 
 /* generate worksheet and workbook */
 const worksheet = XLSX.utils.json_to_sheet(rows);
 var wb = { Workbook: { Views: [{ RTL: true }] }, Sheets: {}, SheetNames: [] };

 XLSX.utils.book_append_sheet(wb, worksheet, "Dates");

 /* fix headers */
 XLSX.utils.sheet_add_aoa(
   worksheet,
   [
     [
       "التاريخ",
       "المبلغ",
       "الزبون",
    
       "المعني",
       "المكلف",
       "ورقة 2000",
       "ورقة 2000",
       "ورقة 1000",
       "ورقة 500",
       "معدن 200",
       "معدن 100",
       "معدن 50",
       "معدن 20",
       "معدن 10",
       "معدن 5",
     ],
   ],
   { origin: "A1" }
 );

 /* calculate column width */
 //  const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
 //  worksheet["!cols"] = [ { wch: max_width } ];

 /* create an XLSX file and try to save to Presidents.xlsx */
 XLSX.writeFile(wb, "المذاخيل.xlsx", { compression: true });
};