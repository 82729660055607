import * as React from 'react';
import {useState} from 'react'
import { styled  } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { add_customer } from './utils';

import LoadingButton from '@mui/lab/LoadingButton';

export default function FullWidthGrid() {
    const [name, setname] = useState("")
    const [lastname, setlastname] = useState("")
    
    const [customer_id, setcustomer_id] = useState(0)
    const [caisse, setcaisse] = useState(localStorage.getItem('company_supabase_id'))
const [sending, setsending] = useState(false)


    const navigate=useNavigate()
    const addCustomer=()=>{
        setsending(true)
        add_customer(caisse,customer_id,name,lastname,).then(res=>{
            navigate('/dashboard/customer')
            setsending(false)
        })

    }
    return (

        <Box sx={{ height: '100%', width: '100%', display: "flex", justifyContent: 'center', alignItems: "center", backgroundColor: "Lavender" }}>
            <Paper elevation={2} sx={{ height: '80%', width: '80%',padding:'80px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}  sx={{  display: "flex", justifyContent: 'center', alignItems: "center"}}>
                    <TextField id="outlined-basic" label="اللقب" onChange={(e)=>setname(e.target.value)} variant="outlined" />
                    </Grid>
                    <Grid item xs={6} sx={{  display: "flex", justifyContent: 'center', alignItems: "center"}} >
                    <TextField id="outlined-basic" label="الاسم" onChange={(e)=>setlastname(e.target.value)} variant="outlined" />
                    </Grid>
                    <Grid item xs={6} sx={{  display: "flex", justifyContent: 'center', alignItems: "center"}} >
                    <TextField id="outlined-basic" label="رقم الزبون" onChange={(e)=>setcustomer_id(e.target.value)} variant="outlined" />
                    </Grid>
                    <Grid item xs={6}  sx={{  display: "flex", justifyContent: 'center', alignItems: "center"}}>
                    <TextField id="outlined-basic" label="الهاتف" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sx={{  display: "flex", justifyContent: 'center', alignItems: "center"}} >
                         <LoadingButton loading={sending} variant="contained" onClick={addCustomer}>اضافة زبون</LoadingButton>
                    </Grid>
                  
                </Grid>
            </Paper>
        </Box>


    );
}
