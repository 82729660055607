import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Badge } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';

import supabase from 'src/supabase.config';
import { Notifications } from '@mui/icons-material';
import { get_new_proceeding } from 'src/pages/caisseoperation/helpers';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 205;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [opendrawer, setopendrawer] = useState(true)
  const isDesktop = useResponsive('up', 'lg');
  const [company, setcompany] = useState([])
  const [number_new_proceeding, setnumber_new_proceeding] = useState(0)
  const get_company = async () => {
    let { data: caisse_sondok, error } = await supabase
      .from('caisse_sondok')
      .select('*')
      .eq('id', localStorage.getItem('company_supabase_id'))
    if (caisse_sondok) {
      setcompany(caisse_sondok)
    }
  }
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    get_new_proceeding(localStorage.getItem('company_supabase_id')).then(res => {
      setnumber_new_proceeding(res)
    })

    get_company()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Subscribe to changes in any record from the collection
  supabase
    .channel('public:proceedings_couting_caisse')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'proceedings_couting_caisse' }, payload => {

      get_new_proceeding(10).then(res => {
        setnumber_new_proceeding(res)
      })


    })
    .subscribe()

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {/* <Logo /> */}

        صندوق مجمع البرهان
      </Box>
      {/* <Button onClick={()=>setopendrawer(!opendrawer)}>d</Button> */}
      <Box sx={{ mb: 1, mx: 2.5, backgroundColor: "" }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {company[0]?.name}
              </Typography>
              {(localStorage.getItem('role')=="admin") ?<Badge badgeContent={number_new_proceeding} sx={{ color: "tomato" }} >
                <Notifications sx={{ color: "tomato" }} />
              </Badge>:""}
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          anchor={"right"}
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',

            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
