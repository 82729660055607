import React from 'react'
import { Page, Text, View, Document, StyleSheet ,Font} from '@react-pdf/renderer';

import Tajwal from "../fonts/tajawal-arabic-400-normal.ttf"

import currencyFormatter from "currency-formatter"


Font.register({
    family: 'Tajwal',
    src: Tajwal
  
  });





// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
     
        height: '40px', width: "100%",
        display:"flex",
        flexDirection: 'row',
      
    },
    footerCollumn:{
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
        borderRight: "1px solid black",
        height: '100%', width: "60%",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#f0f4dc'
    },
    footerCollumn2:{
        borderBottom: "1px solid black",
        borderLeft: "1px solid black",
    
        height: '100%', width: "40%",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    BText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight:'bold',
      
    },
});
const Footer = ({totalDetail,title}) => {
  return (
   
    <View style={styles.section}>
<View style={styles.footerCollumn2}>
<Text style={styles.BText} >
دج
{  currencyFormatter.format(totalDetail, {
             
             decimal: ",",
             thousand: ".",
             precision: 2,
             format: "%v %s", // %s is the symbol and %v is the value
           })}
</Text>
    </View>
    <View style={styles.footerCollumn}>
    <Text style={styles.BText} >
{title}
</Text>
    </View>
    </View>

  )
}

export default Footer