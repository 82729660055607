import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import { get_user } from 'src/pages/conclusion/utils'
// import { get_customer } from 'src/utils/CustomersUtil'
import { fDate, fDateTime } from 'src/utils/formatTime'
import Headerimg from "./ENTETE.png"
import axios from "axios"
import { get_header_url } from 'src/pages/income/printTemplate/utils'
const HeaderPrint = ({ customer }) => {
  var datetime = new Date()
  console.log("Here")
 

  const [label, setlabel] = useState("")
  const [lastName, setlastName] = useState("")
  const [caissier, setcaissier] = useState("")
  const [header_url, setheader_url] = useState("")
  useEffect(() => {
    get_header_url(localStorage.getItem('company_supabase_id')).then(res=>{
      setheader_url(res[0].header_url)
    })
    // get_customer(customer?.client_code).then(res => {
    //   setlastName(res?.last_name)
    //   setlabel(res?.label)
    // })

  
    // axios.post('http://localhost:3004/getUser', {
    //   create_uid: customer?.create_uid[0],
    // })
    // .then(function (response) {
    //   console.log(response.data);  
   
    //   setcaissier(response.data[0]?.name)
       
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }, [])




  return (
    <Box sx={{ width: "100%", padding: "10px", height: "auto", backgroundColor: "", display: "block" }}>
      <img src={header_url} style={{ height: "80px",mt:"20px", width: '100%' }} />
      <Box sx={{ display: "block",height:"80px" ,marginTop:"5px"}} dir="rtl">
        <Typography fontSize={"13px"}>
          الساعة   {fDateTime(datetime).split(' ')[3]}
        </Typography>
        <Typography fontSize={"13px"} align='right' dir="ltr">
        التاريخ    {fDate(datetime)} 
        </Typography>
        {/* <Typography fontSize={"13px"}>
          الزبون  {label + "  " + lastName}
        </Typography>
        <Typography fontSize={"13px"}>
          المكلف  {customer?.taxpayer}
        </Typography> */}
        <Typography fontSize={"13px"}>
          المستخدم  {localStorage?.getItem('username')}
        </Typography>
     
      </Box>

    </Box>
  )
}

export default HeaderPrint