import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Showmodal from "../../../pages/income/showmodal"
import Deletemodal from "../../../pages/income/deletemodal"
import Editmodal from   "../../../pages/income/editmodal"
import Printmodal from "../../../pages/income/printmodal"
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import { Exceltify_json } from './utils';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ data }) {
 
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

 
     
<Printmodal data={data}  />

        <Editmodal data={data} />



        <Showmodal data={data}  />

        <Deletemodal data={data}  />
        <Button onClick={()=>Exceltify_json(data)}>
          Export Excel

          
        </Button>
      </Menu>
    </>
  );
}
