import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { PDFViewer, Font } from '@react-pdf/renderer';
import Tajwal from "./fonts/tajawal-arabic-400-normal.ttf"
import { PrintSharp } from '@mui/icons-material';
import ENTETE from "./images/ENTETE.png"
import Row from "./printTemplate/row"
import Header from "./printTemplate/header"
import Footer from './printTemplate/Footer';
import Tete from './printTemplate/Tete';
import axios from "axios"
import { baseurl } from 'src/utils/constant';
import { useSelector } from 'react-redux';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};



Font.register({
  family: 'Tajwal',
  src: Tajwal

});
// Create styles
const styles = StyleSheet.create({

  page: {
    flexDirection: 'column',
    display: 'flex',

    alignItems: 'center',
    backgroundColor: 'white',
    paddingRight: '100px',
    width: 700,
    padding: 5,
    height: 500,
  },
  section: {
    margin: 10,
    padding: 10,

    paddingRight: '100px',
  },
  container: {
    width: 600,

    height: 500,
    backgroundColor: "white",
  },
  text: {
    fontFamily: "Tajwal",
    fontSize: "10px"
  }, 
  image:{
    height:'60px',
    width:"100%"
  }
});


export default function BasicModal({ data }) {
 

  const Time_Data =useSelector((state)=> state.settings.Time_Data)
  const data_time = localStorage.getItem('data_time')
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pathname, setpathname] = useState('')
  const [pathbool, setpathbool] = useState(true)
  const [printData, setprintData] = useState([])
  useEffect(() => {
    const pathnameurl = window.location.pathname
    console.log(pathnameurl)
    setpathname(pathnameurl)






  },[]);
  return (
    <div>
      <Button hidden variant="outlined" onClick={handleOpen} startIcon={<PrintSharp />} sx={{ direction: 'ltr' }}>
        طباعة اجمالية
      </Button>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <PDFViewer style={styles.container}>
            <Document >
              <Page size="A5" style={styles.page}>
                <Image src={ENTETE} style={styles.image}/>
                <Tete />
                <Header />
                <Row design={"2000"} number={printData?.totalp4} type={"ورق"} />
                <Row design={"1000"} number={printData?.totalp3} type={"ورق"} />
                <Row design={"500"} number={printData?.totalp2} type={"ورق"} />
                <Row design={"200"} number={printData?.totalp1}  type={"ورق"} />



                <Row design={"200"} number={printData?.totalc6}  type={"معدن"} />
                <Row design={"100"} number={printData?.totalc5}  type={"معدن"} />
                <Row design={"50"} number={printData?.totalc4}  type={"معدن"} />
                <Row design={"20"} number={printData?.totalc3}  type={"معدن"} />
                <Row design={"10"} number={printData?.totalc2}  type={"معدن"} />
                <Row design={"5"} number={printData?.totalc1}  type={"معدن"} />

                {pathbool ? <Footer title={"مجموع المداخيل في الصندوق"} totalDetail={printData?.TotalDetail} /> : <Footer title={"مجموع المصاريف في الصندوق"} totalDetail={printData?.TotalSpendingDetail} />}
              </Page>
            </Document>
          </PDFViewer>
          <Button sx={{ direction: "ltr", mt: "20px" }} onClick={handleClose} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:delete:fill" />}>
            الغاء
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
